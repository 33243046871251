import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { consentById } from '../../apis/management/api'
import { i18n } from '../../helper/i18n'
import { getCookie } from '../../helper/helper'

const SecondaryMenuComponent = props => {
  const history = useHistory()
  const path = useLocation().pathname
  const [cookieDomain, setCookieDomain] = useState()
  const [active, setActive] = useState(false)
  const [initDetail, setInitDetail] = useState({})
  const [lang, setLang] = useState(getCookie('lang'))
  useEffect(() => {
    setLang(props.langFn)
  }, [props.langFn])

  const pinMenu = () => {
    setActive(!active)
    if (active) {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover-active'
    } else {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover'
    }
  }

  const getConsentById = async () => {
    const { id } = props
    const { data, status } = await consentById(id)
    if (data && status === 200) {
      setCookieDomain(data.data.domain)
      setInitDetail(data.data)
    }
  }

  useEffect(() => {
    getConsentById()
  }, [])

  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        <a onClick={() => history.push('/domain')} className="logo logo-light">
          <span className="logo-sm">
            <img src="/assets/images/logo-16.svg" alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo.svg" alt="" height="26" />
          </span>
        </a>
        <button
          onClick={e => pinMenu()}
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line"></i>
        </button>
      </div>

      <div id="scrollbar">
        <div className="container-fluid">
          <div id="two-column-menu"></div>
          <ul className="navbar-nav" id="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link menu-link text-white"
                onClick={() => history.push('/domain')}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarLayouts"
              >
                <i className="mdi mdi-chevron-left"></i>
                <span data-key="t-layouts " className="m-0">
                  {' '}
                  {cookieDomain}
                </span>
              </a>
            </li>
            <div className="py-2 px-4">
              <p className="text-white mb-0" style={{ border: '0.2px solid rgb(30 67 94)' }}></p>
            </div>

            <li className="nav-item">
              <a
                className={`nav-link menu-link ${path.includes('/dashboard') ? 'active' : ''} `}
                onClick={() => history.push(`/dashboard/${props.id}`)}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarPages"
              >
                <i className="mdi mdi-view-dashboard-outline"></i>{' '}
                <span data-key="t-authentication">{i18n[lang].dashboard}</span>
              </a>
            </li>

            <li className="nav-item">
              <a
                className={`nav-link menu-link ${path.includes('/acceptcookie') ? 'active' : ''} `}
                onClick={() => history.push(`/acceptcookie/${props.id}`)}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarPages"
              >
                <i className="mdi mdi-clipboard-check"></i>{' '}
                <span data-key="t-pages">{i18n[lang].consentRecords}</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link menu-link ${path.includes('/scancookie') ? 'active' : ''} `}
                onClick={() => history.push(`/scancookie/${props.id}`)}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarPages"
              >
                <i className="mdi mdi-line-scan"></i>{' '}
                <span data-key="t-pages">{i18n[lang].scanCookies}</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link menu-link ${
                  path.includes('/categoriescookie') ? 'active' : ''
                } `}
                onClick={() => history.push(`/categoriescookie/${props.id}`)}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarPages"
              >
                <i className="mdi mdi-shape-outline"></i>{' '}
                <span data-key="t-pages">{i18n[lang].cookieCategory}</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link menu-link ${path.includes('/managecookie') ? 'active' : ''} `}
                onClick={() => history.push(`/managecookie/${props.id}`)}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarPages"
              >
                <i className="mdi mdi-cookie-outline"></i>{' '}
                <span data-key="t-pages">{i18n[lang].bannerManagement}</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link menu-link ${path.includes('/publishcookie') ? 'active' : ''} `}
                onClick={() => history.push(`/publishcookie/${props.id}`)}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarPages"
              >
                <i className="mdi mdi-bullhorn-variant-outline"></i>{' '}
                <span data-key="t-pages">{i18n[lang].publication}</span>
              </a>
            </li>
            <div className="py-2 px-4">
              <p className="text-white  mb-0" style={{ border: '0.2px solid rgb(30 67 94)' }}></p>
            </div>
            {(initDetail?.permission_consents?.[0]?.role === 'owner' ||
              initDetail?.permission_consents?.[0]?.role === 'admin') && (
              <li className="nav-item">
                <a
                  className={`nav-link menu-link ${
                    path.includes('/usermanagement') ? 'active' : ''
                  } `}
                  onClick={() => history.push(`/usermanagement/${props.id}`)}
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarPages"
                >
                  <i className="mdi mdi-account-cog"></i>
                  <span data-key="t-authentication">{i18n[lang].users}</span>
                </a>
              </li>
            )}

            {initDetail?.permission_consents?.[0]?.role !== 'viewer' ? (
              <li className="nav-item">
                <a
                  className={`nav-link menu-link ${path.includes('/log') ? 'active' : ''} `}
                  onClick={() => history.push(`/log/${props.id}`)}
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarPages"
                >
                  <i className="mdi mdi-clipboard-text-clock"></i>
                  <span data-key="t-authentication">{i18n[lang].activityLog}</span>
                </a>
              </li>
            ) : (
              ''
            )}
            {(initDetail?.permission_consents?.[0]?.role === 'owner' ||
              initDetail?.permission_consents?.[0]?.role === 'admin') && (
              <li className="nav-item">
                <a
                  className={`nav-link menu-link ${
                    path.includes('/packagedetails') ? 'active' : ''
                  } `}
                  onClick={() => history.push(`/packagedetails/${props.id}`)}
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarPages"
                >
                  <i className="mdi mdi-image-filter-drama"></i>
                  <span data-key="t-authentication">{i18n[lang].packageDetails}</span>
                </a>
              </li>
            )}

            {initDetail?.permission_consents?.[0]?.role !== 'viewer' && (
              <li className="nav-item">
                <a
                  className={`nav-link menu-link ${path.includes('/ipsetting') ? 'active' : ''} `}
                  onClick={() => history.push(`/ipsetting/${props.id}`)}
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarPages"
                >
                  <i className="mdi mdi-cog"></i>
                  <span data-key="t-authentication">{i18n[lang].settings}</span>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>

    // {/* </div> */}
  )
}

export default SecondaryMenuComponent
