import React, { Fragment, useState, useEffect } from 'react'
import { SketchPicker } from 'react-color'
import {
  getConfigBanner,
  updateConfigBanner,
  consentById,
  uploadLogo,
  packageDetailAll
} from '../../apis/management/api'
import SecondaryMenuComponent from '../../components/menu/secondary-nav'
import {
  RadioBox,
  PreviewBoxDefault,
  PreviewBoxBottom,
  PreviewBoxPopup,
  PreviewBoxBottomLeft,
  PreviewBoxTopLeft,
  PreviewBoxBottomRight,
  PreviewBoxTopRight,
  PreviewBoxFullTop,
  AccordionBox,
  TooltipBox
} from './style'
import { MdLock } from 'react-icons/md'
import styled from 'styled-components'
import ImageUploading from 'react-images-uploading'
import Tooltip from '@mui/material/Tooltip'
import { i18n } from '../../helper/i18n'

import Zoom from '@mui/material/Zoom'
import { getCookie } from '../../helper/helper'
const { REACT_APP_PREDICTIVE } = process.env

const ManageCookieComponent = props => {
  const id = props.match.params.id
  const [colorBackground, setColorBackground] = useState('')
  const [colorButton, setColorButton] = useState('')
  const [colorFont, setColorFont] = useState('')
  const [colorDesc, setColorDesc] = useState('')
  const [fontSize, setFontSize] = useState('16')
  const [titleFontSize, setTitleFontSize] = useState('16')

  const [rangeOpacity, setRangeOpacity] = useState(100)
  const [isShowColorPickerBG, setIsShowColorPickerBG] = useState(false)
  const [isShowColorPickerFT, setIsShowColorPickerFT] = useState(false)
  const [isShowColorPickerDESC, setIsShowColorPickerDESC] = useState(false)

  const [isShowColorPickerAcceptButtonBG, setIsShowColorPickerAcceptButtonBG] = useState(false)
  const [isShowColorPickerAcceptTextColor, setIsShowColorPickerAcceptTextColor] = useState(false)
  const [isShowColorPickerdeclineButtonBorder, setIsShowColorPickerdeclineButtonBorder] =
    useState(false)
  const [isShowColorPickerDeclineTextColor, setIsShowColorPickerDeclineTextColor] = useState(false)
  const [isShowColorPickerLinkTextColor, setIsShowColorPickerLinkTextColor] = useState(false)
  const [isShowColorPickerSettingTextColor, setIsShowColorPickerSettingTextColor] = useState(false)
  const [isShowColorPickerSettingBorder, setIsShowColorPickerSettingBorder] = useState(false)

  const [configPosition, setConfigPosition] = useState({})

  const [inValidateLinkName, setInValidateLinkName] = useState(false)
  const [initDetail, setInitDetail] = useState({})
  let [showTitle, setShowTitle] = useState(true)
  let [showMiniBanner, setShowMiniBanner] = useState(false)
  let [titleTH, setTitleTH] = useState('')
  let [titleEN, setTitleEN] = useState('')
  let [descTH, setDescTH] = useState('')
  let [descEN, setDescEN] = useState('')

  let [acceptTH, setAcceptTH] = useState('')
  let [acceptEN, setAcceptEN] = useState('')

  let [settingTH, setSettingTH] = useState('')
  let [settingEN, setSettingEN] = useState('')

  let [buttonConfirmTH, setButtonConfirmTH] = useState('')
  let [buttonConfirmEN, setButtonConfirmEN] = useState('')

  let [declineTH, setDeclineTH] = useState('')
  let [declineEN, setDeclineEN] = useState('')
  let [customCSS, setCustomCSS] = useState('')

  let [customPolicy, setCustomPolicy] = useState(true)
  let [textPolicyTH, setTextPolicyTH] = useState('')
  let [textPolicyEN, setTextPolicyEN] = useState('')
  let [urlPolicy, setUrlPolicy] = useState('')
  let [objPolicy, setObjPolicy] = useState()
  let [autolang, setAutolang] = useState(false)
  let [showReject, setShowReject] = useState(true)
  let [showCloseBtn, setShowCloseBtn] = useState(true)

  const [packageId, setPackageId] = useState(1)

  const [detailLang, setDetailLang] = useState('th')

  const [bannerLang, setBannerLang] = useState('th')

  const [showDevice, setShowDevice] = useState('desktop')

  const [images, setImages] = useState([])
  const [uploadErrors, setUploadErrors] = useState(false)
  const [uploadIconErrors, setUploadIconErrors] = useState(false)

  const [imagesIcon, setImagesIcon] = useState([])
  const maxNumberIcon = 1

  const [showLogo, setShowLogo] = useState()
  const [iconBanner, setIconBanner] = useState('default')
  const [packageBannerConfig, setPackageBannerConfig] = useState(false)

  const [bannerPosition, setBannerPosition] = useState('default')

  const [acceptButtonBG, setAcceptButtonBG] = useState('')
  const [acceptTextColor, setAcceptTextColor] = useState('')

  const [declineButtonBorder, setDeclineButtonBorder] = useState('')
  const [declineTextColor, setDeclineTextColor] = useState('')

  const [linkTextColor, setLinkTextColor] = useState('')
  const [settingTextColor, setSettingTextColor] = useState('#1298BE')
  const [settingButtonBorder, setSettingButtonBorder] = useState('#1298BE')
  const [settingCustomButton, setSettingCustomButton] = useState('')

  const [optionDetectLang, setOptionDetectLang] = useState('default')
  const [cookieNameDetectLang, setCookieNameDetectLang] = useState('')
  const [optionIconWeb, setOptionIconWeb] = useState('default')

  const [showIcon, setShowIcon] = useState(true)

  const [showCollapseCookieDetail, setShowCollapseCookieDetail] = useState(false)

  const [uploadDraftIcon, setUploadDraftIcon] = useState()
  const [uploadDraftLogo, setUploadDraftLogo] = useState()
  const [validdteNameDetectLang, setValiddteNameDetectLang] = useState(false)

  const [scrollTop, setScrollTop] = useState(0)
  const [isSaveConfig, setIsSaveConfig] = useState({
    show: false,
    status: true,
    msg: ''
  })
  const [lang, setLang] = useState(getCookie('lang'))
  const [isShowLoading, setIsShowLoading] = useState(false)

  useEffect(() => {
    setLang(props.langFn)
  }, [props.langFn])
  const hexChars = /^#[0-9a-fA-F]*$/

  const BannerBox = styled.div`
    .link-setting {
      color: ${settingTextColor}!important;
      border: ${
        settingCustomButton === true ? '1px' : '0px'
      } solid ${settingButtonBorder}!important;
      background-color: transparent !important;
    }
    .btn-link-policy {
      color: ${linkTextColor}!important;
    }
    .btn-accept {
      background-color: ${acceptButtonBG}!important;
      color: ${acceptTextColor}!important;
      border: 1px solid ${acceptButtonBG}!important;
    }
    .btn-reject {
      background-color: transparent !important;
      color: ${declineTextColor}!important;
      border: 1px solid ${declineButtonBorder}!important;
    }
    .bg-modal {
      background: ${colorBackground};

    }

    .background-opacity {
      background-size: calc(${rangeOpacity} / 100))
    }
    .color-header {
      color: ${colorFont}!important;
    }
    .color-content {
      color: ${colorDesc}!important;
    }
    .title-font-size-text {
      font-size: ${titleFontSize - 2}px
    }
    .font-size-text {
      font-size: ${fontSize - 2}px;
    }
    .show-button {
      display: ${showCloseBtn === true ? '' : 'none'};
    }
    .show-button-reject {
      display: ${showReject === true ? '' : 'none'};
    }
  `
  const getPackageDetailAll = async () => {
    let obj = {
      consent_id: id,
      payment_detail: false
    }
    const { data, status } = await packageDetailAll(obj)
    if (status === 200) {
      setPackageId(data.data.packageDetail.package_id)
      setPackageBannerConfig(data.data.packageDetail.package.banner_config)
    }
  }

  const calculator = (color, alpha) => {
    var prefix = Math.round((alpha / 100) * 255)
    var hex = (prefix + 0x10000).toString(16).substr(-2).toUpperCase()
    return color + hex
  }

  useEffect(() => {
    document.title = 'จัดการแบนเนอร์ | CookiePlus'
    getConsentById()
    getPackageDetailAll()
  }, [])

  useEffect(() => {
    getConfig()
  }, [packageBannerConfig])

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollTop])

  const getConfig = async () => {
    const { data, status } = await getConfigBanner(id)
    if (data && data.data && status === 200) {
      let random = Math.floor(100000 + Math.random() * 900000)
      let { decorate, policy, showButton, texting, language, logo, icon, position } = data.data
      setColorBackground(decorate.backgroundColor)
      setColorButton(decorate.buttonColor)
      setColorFont(decorate.titleColor)
      setColorDesc(decorate.textColor)
      setFontSize(decorate?.fontSize ? decorate.fontSize : '16')
      setTitleFontSize(decorate?.titleFontSize ? decorate.titleFontSize : '16')
      setRangeOpacity(parseInt(decorate.backgroundOpacity))
      setShowTitle(texting.showTitle)
      setTitleTH(texting.title.th)
      setTitleEN(texting.title.en)
      setDescTH(texting.text.th)
      setDescEN(texting.text.en)
      setShowCollapseCookieDetail(data?.data?.cookieSetting?.collapseCookieDetail || false)
      setAcceptTH(
        decorate?.buttonAccept?.text?.th ? decorate.buttonAccept.text.th : 'ยินยอมทั้งหมด'
      )
      setAcceptEN(decorate?.buttonAccept?.text?.en ? decorate.buttonAccept.text.en : 'Accept All')

      setButtonConfirmTH(
        decorate?.buttonConfirm?.text?.th ? decorate.buttonConfirm.text.th : 'ยืนยันตัวเลือกของฉัน'
      )
      setButtonConfirmEN(
        decorate?.buttonConfirm?.text?.en ? decorate.buttonConfirm.text.en : 'Confirm preferences'
      )

      setSettingTH(
        decorate?.buttonSetting?.text?.th ? decorate.buttonSetting.text.th : 'การตั้งค่าคุกกี้'
      )
      setSettingEN(
        decorate?.buttonSetting?.text?.en ? decorate.buttonSetting.text.en : 'Preferences'
      )
      setCustomCSS(data?.data?.customCSS || '')

      setDeclineTH(
        decorate?.buttonDecline?.text?.th ? decorate.buttonDecline.text.th : 'ปฏิเสธทั้งหมด'
      )
      setDeclineEN(
        decorate?.buttonDecline?.text?.en ? decorate.buttonDecline.text.en : 'Decline all'
      )
      setSettingCustomButton(
        decorate?.buttonSetting?.borderDefault ? decorate.buttonSetting.borderDefault : false
      )
      setSettingButtonBorder(
        decorate?.buttonSetting?.borderColor ? decorate.buttonSetting.borderColor : '#1298be'
      )
      setCustomPolicy(policy.policyDefault)
      setObjPolicy(policy)
      positionBanner(position ? position : 'default')
      setShowLogo(logo?.showLogo ? logo.showLogo : false)
      setUploadDraftLogo(logo?.draftLogoUrl ? logo.draftLogoUrl + `?cache=${random}` : '')
      setIconBanner(logo?.logoType ? logo.logoType : 'default')
      setShowIcon(icon.showIcon)
      setUploadDraftIcon(icon?.draftIconUrl ? icon.draftIconUrl + `?cache=${random}` : '')

      if (logo && logo.main_draft) {
        setShowLogo(true)
      }
      if (logo && logo.main_draft) {
        setIconBanner('custom')
      }

      if (icon?.small_draft) {
        setShowMiniBanner(true)
      }
      if (icon && icon.small_draft) {
        setIconBanner('custom')
      }

      let url = policy.linkPolicy
      if (!url.match(/^https?:\/\//i)) {
        url = url
      }
      setUrlPolicy(url)
      setShowReject(showButton.showRejectAll)
      setShowCloseBtn(showButton.showCloseBanner)

      setTextPolicyTH(policy.textPolicy.th)
      setTextPolicyEN(policy.textPolicy.en)

      setAutolang(language?.autoDetect ? language.autoDetect : false)
      if (language?.autoDetectBy?.by === 'cookie') {
        setOptionDetectLang(language.autoDetectBy.by)
      }
      setOptionIconWeb(icon.iconType)
      setCookieNameDetectLang(language?.autoDetectBy?.cookieName || '')

      setBannerLang(language?.defaultLanguage ? language.defaultLanguage : 'th')
      setAcceptButtonBG(
        decorate?.buttonAccept?.backgroundColor ? decorate.buttonAccept.backgroundColor : '#1298be'
      )
      setAcceptTextColor(
        decorate?.buttonAccept?.textColor ? decorate.buttonAccept.textColor : '#ffffff'
      )
      setDeclineButtonBorder(
        decorate?.buttonDecline?.borderColor ? decorate.buttonDecline.borderColor : '#1298be'
      )
      setDeclineTextColor(
        decorate?.buttonDecline?.textColor ? decorate.buttonDecline.textColor : '#1298be'
      )
      setLinkTextColor(policy?.buttonLink?.textColor ? policy.buttonLink.textColor : '#1298be')
      setSettingTextColor(
        decorate?.buttonSetting?.textColor ? decorate.buttonSetting.textColor : '#1298be'
      )
    }
  }

  const customTextPolicy = e => {
    let setCustom = e.target.value === '2' ? false : true
    setCustomPolicy(setCustom)
    if (setCustom) {
      setTextPolicyTH('นโยบายคุกกี้')
      setTextPolicyEN('cookie policy')
    } else {
      setTextPolicyTH(objPolicy.textPolicy.th)
      setTextPolicyEN(objPolicy.textPolicy.en)
    }
  }

  const getConsentById = async () => {
    const { data, status } = await consentById(id)
    if (data && status === 200) {
      setInitDetail(data.data)
    }
  }

  const showMiniBannerFn = () => {
    setShowMiniBanner(true)
    setTimeout(() => {
      setShowMiniBanner(false)
    }, 5000)
  }

  const saveConfigBanner = async () => {
    let regx = /^http[s]?:\/\/(www\.)?(.*)?\/?(.)*/ //[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;
    let obj = {
      decorate: {
        backgroundColor: colorBackground,
        buttonColor: colorButton,
        titleColor: colorFont,
        textColor: colorDesc,
        backgroundOpacity: rangeOpacity.toString(),
        fontSize: parseInt(fontSize),
        titleFontSize: parseInt(titleFontSize),
        buttonAccept: {
          textColor: acceptTextColor,
          backgroundColor: acceptButtonBG,
          text: {
            th: acceptTH,
            en: acceptEN
          }
        },
        buttonDecline: {
          textColor: declineTextColor,
          borderColor: declineButtonBorder,
          borderDefault: settingCustomButton,
          text: {
            th: declineTH,
            en: declineEN
          }
        },
        buttonSetting: {
          textColor: settingTextColor,
          borderColor: settingButtonBorder,
          borderDefault: settingCustomButton,
          text: {
            th: settingTH,
            en: settingEN
          }
        },
        buttonConfirm: {
          text: {
            th: buttonConfirmTH,
            en: buttonConfirmEN
          }
        }
      },
      texting: {
        showTitle: showTitle,
        title: {
          th: titleTH,
          en: titleEN
        },
        text: {
          th: descTH,
          en: descEN
        }
      },
      policy: {
        policyDefault: customPolicy,
        textPolicy: {
          th: textPolicyTH,
          en: textPolicyEN
        },
        buttonLink: {
          textColor: linkTextColor
        },
        linkPolicy: urlPolicy
      },
      showButton: {
        showRejectAll: showReject,
        showCloseBanner: showCloseBtn
      },
      language: {
        autoDetect: autolang,
        defaultLanguage: bannerLang,
        autoDetectBy: {
          by: optionDetectLang,
          cookieName: optionDetectLang === 'default' ? '' : cookieNameDetectLang
        }
      },
      logo: {
        showLogo: showLogo ? showLogo : false,
        logoType: iconBanner,
        main_draft: iconBanner !== 'default' ? (uploadDraftLogo ? uploadDraftLogo : '') : ''
      },
      icon: {
        showIcon: showIcon,
        iconType: optionIconWeb,
        small_draft: optionIconWeb !== 'default' ? (uploadDraftIcon ? uploadDraftIcon : '') : ''
      },
      position: bannerPosition ? bannerPosition : 'default',
      cookieSetting: {
        collapseCookieDetail: showCollapseCookieDetail
      }
    }
    if (customCSS) {
      obj.customCSS = customCSS //.replace(/\n/g, '')
    }
    if (!cookieNameDetectLang) {
      setValiddteNameDetectLang(true)
    } else {
      setValiddteNameDetectLang(false)
    }
    if (!urlPolicy) {
      setInValidateLinkName(false)
      const { data, status } = await updateConfigBanner(obj, id)
      if (data && status === 200) {
        setIsSaveConfig({
          show: true,
          status: true,
          msg: i18n[lang].settingsSaved
        })
        getConfig()
        setTimeout(() => {
          setIsSaveConfig({ show: false })
        }, 5000)
        setIsShowLoading(false)
      } else {
        setIsSaveConfig({
          show: true,
          status: false,
          msg: i18n[lang].settingsSaveFailed
        })
        setTimeout(() => {
          setIsSaveConfig({ show: false })
        }, 5000)
        setIsShowLoading(false)
      }
    } else {
      if (urlPolicy.match(regx)) {
        setInValidateLinkName(false)
        const { data, status } = await updateConfigBanner(obj, id)
        if (data && status === 200) {
          setIsSaveConfig({
            show: true,
            status: true,
            msg: i18n[lang].settingsSaved
          })
          setTimeout(() => {
            setIsSaveConfig({ show: false })
          }, 5000)
          setIsShowLoading(false)
        } else {
          setIsSaveConfig({
            show: true,
            status: false,
            msg: i18n[lang].settingsSaveFailed
          })
          setTimeout(() => {
            setIsSaveConfig({ show: false })
          }, 5000)
          setIsShowLoading(false)
        }
      } else {
        setTimeout(() => {
          setIsShowLoading(false)
        }, 500)
        setIsSaveConfig({
          show: true,
          status: false,
          msg: i18n[lang].settingsSaveFailed
        })
        setTimeout(() => {
          setIsSaveConfig({ show: false })
        }, 5000)
        setInValidateLinkName(true)
      }
    }
  }

  const handleShowlogo = isShow => {
    setShowLogo(isShow)
  }
  const ExampleBannerDefault = () => {
    return (
      <BannerBox>
        <Fragment>
          <PreviewBoxDefault config={configPosition}>
            <div className={` banner-preview`}>
              <div className="modal-dialog modal-lg modal-dialog-centered m-0">
                <div className="modal-content bg-modal background-opacity">
                  <div className="modal-header">
                    <h5 className="modal-title color-header title-font-size-text">
                      {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                    </h5>
                    <button type="button" className="btn-close show-button"></button>
                  </div>
                  <div className={` modal-body text-left`}>
                    <div className="row">
                      <div
                        className={`color-content font-size-text break-word ${
                          showDevice === 'desktop'
                            ? showReject === true
                              ? showReject
                                ? 'col-5'
                                : 'col-7'
                              : 'col-7'
                            : showDevice === 'tablet'
                            ? showReject === true
                              ? 'col-12'
                              : 'col-12'
                            : showDevice === 'mobile'
                            ? showReject === true
                              ? 'col-12'
                              : 'col-12'
                            : ''
                        } `}
                      >
                        {detailLang === 'th' ? descTH : descEN}
                        <a
                          onClick={() => window.open(urlPolicy, '_blank').focus()}
                          className={`${
                            showDevice === 'mobile' ? 'w-100 mt-2' : ''
                          } btn-link-policy text-decoration-underline mx-2 font-size-text`}
                        >
                          <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN} </span>
                        </a>
                      </div>
                      <div
                        className={`p-5per ${
                          showDevice === 'desktop'
                            ? showReject === true
                              ? showReject === true
                                ? 'col-7 text-center'
                                : 'col-5 text-center'
                              : 'col-5 text-right'
                            : showDevice === 'tablet'
                            ? showReject === true
                              ? 'col-12 text-right'
                              : 'col-12 text-right'
                            : showDevice === 'mobile'
                            ? showReject === true
                              ? 'col-12 text-center'
                              : 'col-12 text-center'
                            : ''
                        } `}
                      >
                        <button
                          type="button"
                          className={`${
                            showDevice === 'mobile' ? 'w-100 mt-2 mx-0' : 'mx-1 mt-2'
                          } btn link-setting  waves-effect waves-light  font-size-text px-1`}
                        >
                          {detailLang === 'th' ? settingTH : settingEN}
                        </button>
                        {showReject && (
                          <button
                            type="button"
                            className={`${
                              showDevice === 'mobile' ? 'w-100 mt-1 right-8 mx-2' : ''
                            } btn btn-accept btn-primary ${
                              settingCustomButton === false
                                ? 'mx-1 mt-2'
                                : showDevice === 'mobile'
                                ? 'w-100 mt-1 mx-2'
                                : 'mx-1 mt-2'
                            } btn btn-reject btn-outline-primary waves-effect waves-light  font-size-text show-button-reject px-1`}
                          >
                            {detailLang === 'th' ? declineTH : declineEN}
                          </button>
                        )}

                        <button
                          type="button"
                          className={`${
                            showDevice === 'mobile' ? 'w-100 mt-1 right-8 mx-2' : ''
                          } btn btn-accept btn-primary ${
                            settingCustomButton === false
                              ? 'mx-1 mt-2'
                              : showDevice === 'mobile'
                              ? 'mx-2'
                              : 'mx-1 mt-2'
                          } font-size-text px-1`}
                        >
                          {detailLang === 'th' ? acceptTH : acceptEN}
                        </button>
                      </div>
                    </div>
                  </div>
                  {showLogo ? (
                    <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                      <img
                        src={`${
                          iconBanner === 'default'
                            ? '../assets/images/Presented.svg'
                            : uploadDraftLogo
                            ? uploadDraftLogo
                            : '../assets/images/Presented.svg'
                        }`}
                        alt="predictive"
                        height="14"
                      />
                    </div>
                  ) : packageId === 1 ? (
                    <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                      <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {showMiniBanner && (
                <div className="box-preview-mini-cookie">
                  {optionIconWeb === 'default' ? (
                    <div className="preview-mini-cookie">
                      <img src="../assets/images/cookie.png" alt="" height="25" />
                    </div>
                  ) : uploadDraftIcon ? (
                    <div className="preview-mini-cookie">
                      <img src={uploadDraftIcon} alt="" width="20" height="20" />
                    </div>
                  ) : (
                    <ImageUploading value={imagesIcon} dataURLKey="Icon_url">
                      {({ imageList }) => (
                        <Fragment>
                          {imageList.map((image, index) => (
                            <div className="preview-mini-cookie" key={index}>
                              <img src={image['Icon_url']} alt="" width="20" height="20" />
                            </div>
                          ))}
                        </Fragment>
                      )}
                    </ImageUploading>
                  )}
                </div>
              )}
            </div>
          </PreviewBoxDefault>
        </Fragment>
      </BannerBox>
    )
  }
  const ExampleBannerBottom = () => {
    return (
      <BannerBox>
        <Fragment>
          <PreviewBoxBottom config={configPosition}>
            <div className={` banner-preview`}>
              <div className="modal-dialog modal-lg modal-dialog-centered m-0">
                <div className="modal-content bg-modal background-opacity">
                  <div className="modal-header">
                    <h5 className="modal-title color-header title-font-size-text">
                      {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                    </h5>
                    <button type="button" className="btn-close show-button"></button>
                  </div>
                  <div className={` modal-body text-left`}>
                    <div className="row">
                      <div
                        className={`color-content font-size-text break-word ${
                          showDevice === 'desktop'
                            ? showReject === true
                              ? showReject
                                ? 'col-5'
                                : 'col-7'
                              : 'col-7'
                            : showDevice === 'tablet'
                            ? showReject === true
                              ? 'col-12'
                              : 'col-12'
                            : showDevice === 'mobile'
                            ? showReject === true
                              ? 'col-12'
                              : 'col-12'
                            : ''
                        } `}
                      >
                        {detailLang === 'th' ? descTH : descEN}
                        <a
                          onClick={() => window.open(urlPolicy, '_blank').focus()}
                          className={`${
                            showDevice === 'mobile' ? 'w-100 mt-2' : ''
                          } btn-link-policy text-decoration-underline mx-2 font-size-text`}
                        >
                          <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN} </span>
                        </a>
                      </div>
                      <div
                        className={`p-5per ${
                          showDevice === 'desktop'
                            ? showReject === true
                              ? showReject === true
                                ? 'col-7 text-center'
                                : 'col-5 text-center'
                              : 'col-5 text-right'
                            : showDevice === 'tablet'
                            ? showReject === true
                              ? 'col-12 text-right'
                              : 'col-12 text-right'
                            : showDevice === 'mobile'
                            ? showReject === true
                              ? 'col-12 text-center'
                              : 'col-12 text-center'
                            : ''
                        } `}
                      >
                        <button
                          type="button"
                          className={`${
                            showDevice === 'mobile' ? 'w-100 mt-2 mx-0' : 'mx-1'
                          } btn link-setting  waves-effect waves-light  font-size-text `}
                        >
                          {detailLang === 'th' ? settingTH : settingEN}
                        </button>
                        {showReject && (
                          <button
                            type="button"
                            className={`${
                              showDevice === 'mobile' ? 'w-100 mt-2 mx-0' : 'mx-2'
                            } btn btn-reject btn-outline-primary waves-effect waves-light  font-size-text show-button-reject`}
                          >
                            {detailLang === 'th' ? declineTH : declineEN}
                          </button>
                        )}

                        <button
                          type="button"
                          className={`${
                            showDevice === 'mobile' ? 'w-100 mt-2 right-8' : ''
                          } btn btn-accept btn-primary mx-2 font-size-text`}
                        >
                          {detailLang === 'th' ? acceptTH : acceptEN}
                        </button>
                      </div>
                    </div>
                  </div>
                  {showLogo ? (
                    <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                      <img
                        src={`${
                          iconBanner === 'default'
                            ? '../assets/images/Presented.svg'
                            : uploadDraftLogo
                            ? uploadDraftLogo
                            : '../assets/images/Presented.svg'
                        }`}
                        alt="predictive"
                        height="14"
                      />
                    </div>
                  ) : packageId === 1 ? (
                    <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                      <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {showMiniBanner && (
                <div className="box-preview-mini-cookie">
                  {optionIconWeb === 'default' ? (
                    <div className="preview-mini-cookie">
                      <img src="../assets/images/cookie.png" alt="" height="25" />
                    </div>
                  ) : uploadDraftIcon ? (
                    <div className="preview-mini-cookie">
                      <img src={uploadDraftIcon} alt="" width="20" height="20" />
                    </div>
                  ) : (
                    <ImageUploading value={imagesIcon} dataURLKey="Icon_url">
                      {({ imageList }) => (
                        <Fragment>
                          {imageList.map((image, index) => (
                            <div className="preview-mini-cookie" key={index}>
                              <img src={image['Icon_url']} alt="" width="20" height="20" />
                            </div>
                          ))}
                        </Fragment>
                      )}
                    </ImageUploading>
                  )}
                </div>
              )}
            </div>
          </PreviewBoxBottom>
        </Fragment>
      </BannerBox>
    )
  }
  const ExampleBannerPopup = () => {
    return (
      <BannerBox>
        <Fragment>
          <PreviewBoxPopup config={configPosition}>
            <div className="backdrop">
              <div className={` banner-preview ${showDevice === 'mobile' && 'p-0 '}`}>
                <div
                  className={`${
                    showDevice === 'mobile' && 'p-0 '
                  }modal-dialog modal-lg modal-dialog-centered banner-preview-content`}
                >
                  <div
                    className={` modal-content bg-modal background-opacity ${
                      showDevice === 'mobile' && 'h-450 '
                    }`}
                  >
                    <div className="modal-header pb-1">
                      <h5 className="modal-title color-header title-font-size-text">
                        {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                      </h5>
                      <button type="button" className="btn-close show-button"></button>
                    </div>
                    <div
                      className={` modal-body text-left pt-1 ${
                        showDevice === 'mobile' && ' h-450'
                      }`}
                    >
                      <div className="row">
                        <div
                          className={`color-content font-size-text mb-3 break-word ${
                            showDevice === 'desktop'
                              ? showReject === true
                                ? showReject
                                  ? 'col-12'
                                  : 'col-7'
                                : 'col-12'
                              : showDevice === 'tablet'
                              ? showReject === true
                                ? 'col-12'
                                : 'col-12'
                              : showDevice === 'mobile'
                              ? showReject === true
                                ? 'col-12'
                                : 'col-12'
                              : ''
                          } `}
                        >
                          {detailLang === 'th' ? descTH : descEN}

                          <a
                            onClick={() => window.open(urlPolicy, '_blank').focus()}
                            className={`${
                              showDevice === 'mobile' ? 'w-100 mt-2' : ''
                            } btn-link-policy text-decoration-underline mx-2 font-size-text`}
                          >
                            <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN} </span>
                          </a>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div
                          className={`${
                            showDevice === 'mobile'
                              ? 'col-12'
                              : showReject === true
                              ? 'col-12 px-1 py-2'
                              : 'col-6 px-1 py-2'
                          }`}
                        >
                          <button
                            type="button"
                            className={`btn link-setting  btn-outline-primary waves-effect waves-light font-size-text  w-100 `}
                          >
                            {detailLang === 'th' ? settingTH : settingEN}
                          </button>
                        </div>{' '}
                        {showReject && (
                          <div
                            className={`${
                              showDevice === 'mobile' ? 'col-12 mt-3' : 'col-6 px-1 py-2'
                            }`}
                          >
                            <button
                              type="button"
                              className="btn btn-reject btn-outline-primary waves-effect waves-light font-size-text show-button-reject w-100 "
                            >
                              {detailLang === 'th' ? declineTH : declineEN}
                            </button>
                          </div>
                        )}
                        <div
                          className={`${
                            showDevice === 'mobile' ? 'col-12 mt-3' : 'col-6  px-1 py-2'
                          }`}
                        >
                          {' '}
                          <button
                            type="button"
                            className="btn btn-accept btn-primary mx-0 font-size-text w-100 "
                          >
                            {detailLang === 'th' ? acceptTH : acceptEN}
                          </button>
                        </div>
                      </div>
                    </div>
                    {showLogo ? (
                      <div className="leaflet-bar text-center footer-banner pt-1 fs-12">
                        <img
                          src={`${
                            iconBanner === 'default'
                              ? '../assets/images/Presented.svg'
                              : uploadDraftLogo
                              ? uploadDraftLogo
                              : '../assets/images/Presented.svg'
                          }`}
                          alt="predictive"
                          height="14"
                        />
                      </div>
                    ) : packageId === 1 ? (
                      <div className="leaflet-bar text-center footer-banner pt-1 fs-12">
                        <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {showMiniBanner && (
                  <div className="box-preview-mini-cookie">
                    {optionIconWeb === 'default' ? (
                      <div className="preview-mini-cookie">
                        <img src="../assets/images/cookie.png" alt="" height="25" />
                      </div>
                    ) : uploadDraftIcon ? (
                      <div className="preview-mini-cookie">
                        <img src={uploadDraftIcon} alt="" width="20" height="20" />
                      </div>
                    ) : (
                      <ImageUploading value={imagesIcon} dataURLKey="Icon_url">
                        {({ imageList }) => (
                          <Fragment>
                            {imageList.map((image, index) => (
                              <div className="preview-mini-cookie" key={index}>
                                <img src={image['Icon_url']} alt="" width="20" height="20" />
                              </div>
                            ))}
                          </Fragment>
                        )}
                      </ImageUploading>
                    )}
                  </div>
                )}
              </div>
            </div>
          </PreviewBoxPopup>
        </Fragment>
      </BannerBox>
    )
  }
  const ExampleBannerBottomLeft = () => {
    return (
      <BannerBox>
        <PreviewBoxBottomLeft config={configPosition}>
          <div
            className={` ${showDevice === 'mobile' ? 'banner-previewmobile' : 'banner-preview'}`}
          >
            <div className="modal-dialog modal-lg modal-dialog-centered m-0">
              <div className="modal-content bg-modal background-opacity">
                <div className="modal-header">
                  <h5 className="modal-title color-header title-font-size-text">
                    {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                  </h5>
                  <button type="button" className="btn-close show-button"></button>
                </div>
                <div
                  className={` modal-body text-left pt-1 ${showDevice === 'mobile' && ' h-450'}`}
                >
                  <div className="row">
                    <div
                      className={`color-content font-size-text mb-3 break-word ${
                        showDevice === 'desktop'
                          ? showReject === true
                            ? showReject
                              ? 'col-12'
                              : 'col-7'
                            : 'col-12'
                          : showDevice === 'tablet'
                          ? showReject === true
                            ? 'col-12'
                            : 'col-12'
                          : showDevice === 'mobile'
                          ? showReject === true
                            ? 'col-12'
                            : 'col-12'
                          : ''
                      } `}
                    >
                      {detailLang === 'th' ? descTH : descEN}

                      <a
                        onClick={() => window.open(urlPolicy, '_blank').focus()}
                        className={`${
                          showDevice === 'mobile' ? 'w-100 mt-2' : ''
                        } btn-link-policy text-decoration-underline mx-2 font-size-text`}
                      >
                        <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN} </span>
                      </a>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className={`col-12 mt-2`}>
                      <button
                        type="button"
                        className={`btn link-setting  btn-outline-primary waves-effect waves-light font-size-text  w-100 `}
                      >
                        {detailLang === 'th' ? settingTH : settingEN}
                      </button>
                    </div>{' '}
                    {showReject && (
                      <div className={`col-12 mt-2`}>
                        <button
                          type="button"
                          className="btn btn-reject btn-outline-primary waves-effect waves-light font-size-text show-button-reject w-100 "
                        >
                          {detailLang === 'th' ? declineTH : declineEN}
                        </button>
                      </div>
                    )}
                    <div className={`col-12 mt-2`}>
                      {' '}
                      <button
                        type="button"
                        className="btn btn-accept btn-primary mx-0 font-size-text w-100 "
                      >
                        {detailLang === 'th' ? acceptTH : acceptEN}
                      </button>
                    </div>
                  </div>
                </div>

                {showLogo ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img
                      src={`${
                        iconBanner === 'default'
                          ? '../assets/images/Presented.svg'
                          : uploadDraftLogo
                          ? uploadDraftLogo
                          : '../assets/images/Presented.svg'
                      }`}
                      alt="predictive"
                      height="14"
                    />
                  </div>
                ) : packageId === 1 ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {showMiniBanner && (
              <div className="box-preview-mini-cookie">
                {optionIconWeb === 'default' ? (
                  <div className="preview-mini-cookie">
                    <img src="../assets/images/cookie.png" alt="" height="25" />
                  </div>
                ) : uploadDraftIcon ? (
                  <div className="preview-mini-cookie">
                    <img src={uploadDraftIcon} alt="" width="20" height="20" />
                  </div>
                ) : (
                  <ImageUploading value={imagesIcon} dataURLKey="Icon_url">
                    {({ imageList }) => (
                      <Fragment>
                        {imageList.map((image, index) => (
                          <div className="preview-mini-cookie" key={index}>
                            <img src={image['Icon_url']} alt="" width="20" height="20" />
                          </div>
                        ))}
                      </Fragment>
                    )}
                  </ImageUploading>
                )}
              </div>
            )}
          </div>
        </PreviewBoxBottomLeft>
      </BannerBox>
    )
  }
  const ExampleBannerTopLeft = () => {
    return (
      <BannerBox>
        <PreviewBoxTopLeft>
          <div
            className={` ${showDevice === 'mobile' ? 'banner-previewmobile' : 'banner-preview'}`}
          >
            <div className="modal-dialog modal-lg modal-dialog-centered m-0">
              <div className="modal-content bg-modal background-opacity">
                <div className="modal-header">
                  <h5 className="modal-title color-header title-font-size-text">
                    {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                  </h5>
                  <button type="button" className="btn-close show-button"></button>
                </div>
                <div
                  className={` modal-body text-left pt-1 ${showDevice === 'mobile' && ' h-450'}`}
                >
                  <div className="row">
                    <div
                      className={`color-content font-size-text mb-3 break-word ${
                        showDevice === 'desktop'
                          ? showReject === true
                            ? showReject
                              ? 'col-12'
                              : 'col-7'
                            : 'col-12'
                          : showDevice === 'tablet'
                          ? showReject === true
                            ? 'col-12'
                            : 'col-12'
                          : showDevice === 'mobile'
                          ? showReject === true
                            ? 'col-12'
                            : 'col-12'
                          : ''
                      } `}
                    >
                      {detailLang === 'th' ? descTH : descEN}

                      <a
                        onClick={() => window.open(urlPolicy, '_blank').focus()}
                        className={`${
                          showDevice === 'mobile' ? 'w-100 mt-2' : ''
                        } btn-link-policy text-decoration-underline mx-2 font-size-text`}
                      >
                        <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN} </span>
                      </a>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className={`col-12 mt-2`}>
                      <button
                        type="button"
                        className={`btn link-setting  btn-outline-primary waves-effect waves-light font-size-text  w-100 `}
                      >
                        {detailLang === 'th' ? settingTH : settingEN}
                      </button>
                    </div>{' '}
                    {showReject && (
                      <div className={`col-12 mt-2`}>
                        <button
                          type="button"
                          className="btn btn-reject btn-outline-primary waves-effect waves-light font-size-text show-button-reject w-100 "
                        >
                          {detailLang === 'th' ? declineTH : declineEN}
                        </button>
                      </div>
                    )}
                    <div className={`col-12 mt-2`}>
                      {' '}
                      <button
                        type="button"
                        className="btn btn-accept btn-primary mx-0 font-size-text w-100 "
                      >
                        {detailLang === 'th' ? acceptTH : acceptEN}
                      </button>
                    </div>
                  </div>
                </div>

                {showLogo ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img
                      src={`${
                        iconBanner === 'default'
                          ? '../assets/images/Presented.svg'
                          : uploadDraftLogo
                          ? uploadDraftLogo
                          : '../assets/images/Presented.svg'
                      }`}
                      alt="predictive"
                      height="14"
                    />
                  </div>
                ) : packageId === 1 ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {showMiniBanner && (
              <div className="box-preview-mini-cookie">
                {optionIconWeb === 'default' ? (
                  <div className="preview-mini-cookie">
                    <img src="../assets/images/cookie.png" alt="" height="25" />
                  </div>
                ) : uploadDraftIcon ? (
                  <div className="preview-mini-cookie">
                    <img src={uploadDraftIcon} alt="" width="20" height="20" />
                  </div>
                ) : (
                  <ImageUploading value={imagesIcon} dataURLKey="Icon_url">
                    {({ imageList }) => (
                      <Fragment>
                        {imageList.map((image, index) => (
                          <div className="preview-mini-cookie" key={index}>
                            <img src={image['Icon_url']} alt="" width="20" height="20" />
                          </div>
                        ))}
                      </Fragment>
                    )}
                  </ImageUploading>
                )}
              </div>
            )}
          </div>
        </PreviewBoxTopLeft>
      </BannerBox>
    )
  }
  const ExampleBannerBottomRight = () => {
    return (
      <BannerBox>
        <PreviewBoxBottomRight config={configPosition}>
          <div
            className={` ${showDevice === 'mobile' ? 'banner-previewmobile' : 'banner-preview'}`}
          >
            <div className="modal-dialog modal-lg modal-dialog-centered m-0">
              <div className="modal-content bg-modal background-opacity">
                <div className="modal-header">
                  <h5 className="modal-title color-header title-font-size-text">
                    {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                  </h5>
                  <button type="button" className="btn-close show-button"></button>
                </div>
                <div
                  className={` modal-body text-left pt-1 ${showDevice === 'mobile' && ' h-450'}`}
                >
                  <div className="row">
                    <div
                      className={`color-content font-size-text mb-3 break-word ${
                        showDevice === 'desktop'
                          ? showReject === true
                            ? showReject
                              ? 'col-12'
                              : 'col-7'
                            : 'col-12'
                          : showDevice === 'tablet'
                          ? showReject === true
                            ? 'col-12'
                            : 'col-12'
                          : showDevice === 'mobile'
                          ? showReject === true
                            ? 'col-12'
                            : 'col-12'
                          : ''
                      } `}
                    >
                      {detailLang === 'th' ? descTH : descEN}

                      <a
                        onClick={() => window.open(urlPolicy, '_blank').focus()}
                        className={`${
                          showDevice === 'mobile' ? 'w-100 mt-2' : ''
                        } btn-link-policy text-decoration-underline mx-2 font-size-text`}
                      >
                        <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN} </span>
                      </a>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className={`col-12 mt-2`}>
                      <button
                        type="button"
                        className={`btn link-setting  btn-outline-primary waves-effect waves-light font-size-text  w-100 `}
                      >
                        {detailLang === 'th' ? settingTH : settingEN}
                      </button>
                    </div>{' '}
                    {showReject && (
                      <div className={`col-12 mt-2`}>
                        <button
                          type="button"
                          className="btn btn-reject btn-outline-primary waves-effect waves-light font-size-text show-button-reject w-100 "
                        >
                          {detailLang === 'th' ? declineTH : declineEN}
                        </button>
                      </div>
                    )}
                    <div className={`col-12 mt-2`}>
                      {' '}
                      <button
                        type="button"
                        className="btn btn-accept btn-primary mx-0 font-size-text w-100 "
                      >
                        {detailLang === 'th' ? acceptTH : acceptEN}
                      </button>
                    </div>
                  </div>
                </div>

                {showLogo ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img
                      src={`${
                        iconBanner === 'default'
                          ? '../assets/images/Presented.svg'
                          : uploadDraftLogo
                          ? uploadDraftLogo
                          : '../assets/images/Presented.svg'
                      }`}
                      alt="predictive"
                      height="14"
                    />
                  </div>
                ) : packageId === 1 ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {showMiniBanner && (
              <div className="box-preview-mini-cookie">
                {optionIconWeb === 'default' ? (
                  <div className="preview-mini-cookie">
                    <img src="../assets/images/cookie.png" alt="" height="25" />
                  </div>
                ) : uploadDraftIcon ? (
                  <div className="preview-mini-cookie">
                    <img src={uploadDraftIcon} alt="" width="20" height="20" />
                  </div>
                ) : (
                  <ImageUploading value={imagesIcon} dataURLKey="Icon_url">
                    {({ imageList }) => (
                      <Fragment>
                        {imageList.map((image, index) => (
                          <div className="preview-mini-cookie" key={index}>
                            <img src={image['Icon_url']} alt="" width="20" height="20" />
                          </div>
                        ))}
                      </Fragment>
                    )}
                  </ImageUploading>
                )}
              </div>
            )}
          </div>
        </PreviewBoxBottomRight>
      </BannerBox>
    )
  }
  const ExampleBannerTopRight = () => {
    return (
      <BannerBox>
        <PreviewBoxTopRight config={configPosition}>
          <div
            className={` ${showDevice === 'mobile' ? 'banner-previewmobile' : 'banner-preview'}`}
          >
            <div className="modal-dialog modal-lg modal-dialog-centered m-0">
              <div className="modal-content bg-modal background-opacity">
                <div className="modal-header">
                  <h5 className="modal-title color-header title-font-size-text">
                    {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                  </h5>
                  <button type="button" className="btn-close show-button"></button>
                </div>
                <div
                  className={` modal-body text-left pt-1 ${showDevice === 'mobile' && ' h-450'}`}
                >
                  <div className="row">
                    <div
                      className={`color-content font-size-text mb-3 break-word ${
                        showDevice === 'desktop'
                          ? showReject === true
                            ? showReject
                              ? 'col-12'
                              : 'col-7'
                            : 'col-12'
                          : showDevice === 'tablet'
                          ? showReject === true
                            ? 'col-12'
                            : 'col-12'
                          : showDevice === 'mobile'
                          ? showReject === true
                            ? 'col-12'
                            : 'col-12'
                          : ''
                      } `}
                    >
                      {detailLang === 'th' ? descTH : descEN}

                      <a
                        onClick={() => window.open(urlPolicy, '_blank').focus()}
                        className={`${
                          showDevice === 'mobile' ? 'w-100 mt-2' : ''
                        } btn-link-policy text-decoration-underline mx-2 font-size-text`}
                      >
                        <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN} </span>
                      </a>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className={`col-12 mt-2`}>
                      <button
                        type="button"
                        className={`btn link-setting  btn-outline-primary waves-effect waves-light font-size-text  w-100 `}
                      >
                        {detailLang === 'th' ? settingTH : settingEN}
                      </button>
                    </div>{' '}
                    {showReject && (
                      <div className={`col-12 mt-2`}>
                        <button
                          type="button"
                          className="btn btn-reject btn-outline-primary waves-effect waves-light font-size-text show-button-reject w-100 "
                        >
                          {detailLang === 'th' ? declineTH : declineEN}
                        </button>
                      </div>
                    )}
                    <div className={`col-12 mt-2`}>
                      {' '}
                      <button
                        type="button"
                        className="btn btn-accept btn-primary mx-0 font-size-text w-100 "
                      >
                        {detailLang === 'th' ? acceptTH : acceptEN}
                      </button>
                    </div>
                  </div>
                </div>

                {showLogo ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img
                      src={`${
                        iconBanner === 'default'
                          ? '../assets/images/Presented.svg'
                          : uploadDraftLogo
                          ? uploadDraftLogo
                          : '../assets/images/Presented.svg'
                      }`}
                      alt="predictive"
                      height="14"
                    />
                  </div>
                ) : packageId === 1 ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {showMiniBanner && (
              <div className="box-preview-mini-cookie">
                {optionIconWeb === 'default' ? (
                  <div className="preview-mini-cookie">
                    <img src="../assets/images/cookie.png" alt="" height="25" />
                  </div>
                ) : uploadDraftIcon ? (
                  <div className="preview-mini-cookie">
                    <img src={uploadDraftIcon} alt="" width="20" height="20" />
                  </div>
                ) : (
                  <ImageUploading value={imagesIcon} dataURLKey="Icon_url">
                    {({ imageList }) => (
                      <Fragment>
                        {imageList.map((image, index) => (
                          <div className="preview-mini-cookie" key={index}>
                            <img src={image['Icon_url']} alt="" width="20" height="20" />
                          </div>
                        ))}
                      </Fragment>
                    )}
                  </ImageUploading>
                )}
              </div>
            )}
          </div>
        </PreviewBoxTopRight>
      </BannerBox>
    )
  }
  const ExampleBannerFullTop = () => {
    return (
      <BannerBox>
        <Fragment>
          <PreviewBoxFullTop config={configPosition}>
            <div className={` banner-preview`}>
              <div className="modal-dialog modal-lg modal-dialog-centered m-0">
                <div className="modal-content bg-modal background-opacity">
                  <div className="modal-header">
                    <h5 className="modal-title color-header title-font-size-text">
                      {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                    </h5>
                    <button type="button" className="btn-close show-button"></button>
                  </div>
                  <div className={` modal-body text-left`}>
                    <div className="row">
                      <div
                        className={`color-content font-size-text break-word ${
                          showDevice === 'desktop'
                            ? showReject === true
                              ? showReject
                                ? 'col-5'
                                : 'col-7'
                              : 'col-7'
                            : showDevice === 'tablet'
                            ? showReject === true
                              ? 'col-12'
                              : 'col-12'
                            : showDevice === 'mobile'
                            ? showReject === true
                              ? 'col-12'
                              : 'col-12'
                            : ''
                        } `}
                      >
                        {detailLang === 'th' ? descTH : descEN}
                        <a
                          onClick={() => window.open(urlPolicy, '_blank').focus()}
                          className={`${
                            showDevice === 'mobile' ? 'w-100 mt-2' : ''
                          } btn-link-policy text-decoration-underline mx-2 font-size-text`}
                        >
                          <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN} </span>
                        </a>
                      </div>
                      <div
                        className={`p-5per ${
                          showDevice === 'desktop'
                            ? showReject === true
                              ? showReject === true
                                ? 'col-7 text-center'
                                : 'col-5 text-center'
                              : 'col-5 text-right'
                            : showDevice === 'tablet'
                            ? showReject === true
                              ? 'col-12 text-right'
                              : 'col-12 text-right'
                            : showDevice === 'mobile'
                            ? showReject === true
                              ? 'col-12 text-center'
                              : 'col-12 text-center'
                            : ''
                        } `}
                      >
                        <button
                          type="button"
                          className={`${
                            showDevice === 'mobile' ? 'w-100 mt-2 mx-0' : 'mx-1'
                          } btn link-setting  waves-effect waves-light  font-size-text `}
                        >
                          {detailLang === 'th' ? settingTH : settingEN}
                        </button>
                        {showReject && (
                          <button
                            type="button"
                            className={`${
                              showDevice === 'mobile' ? 'w-100 mt-2 mx-0' : 'mx-2'
                            } btn btn-reject btn-outline-primary waves-effect waves-light  font-size-text show-button-reject`}
                          >
                            {detailLang === 'th' ? declineTH : declineEN}
                          </button>
                        )}

                        <button
                          type="button"
                          className={`${
                            showDevice === 'mobile' ? 'w-100 mt-2 right-8' : ''
                          } btn btn-accept btn-primary mx-2 font-size-text`}
                        >
                          {detailLang === 'th' ? acceptTH : acceptEN}
                        </button>
                      </div>
                    </div>
                  </div>
                  {showLogo ? (
                    <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                      <img
                        src={`${
                          iconBanner === 'default'
                            ? '../assets/images/Presented.svg'
                            : uploadDraftLogo
                            ? uploadDraftLogo
                            : '../assets/images/Presented.svg'
                        }`}
                        alt="predictive"
                        height="14"
                      />
                    </div>
                  ) : packageId === 1 ? (
                    <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                      <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {showMiniBanner && (
                <div className="box-preview-mini-cookie">
                  {optionIconWeb === 'default' ? (
                    <div className="preview-mini-cookie">
                      <img src="../assets/images/cookie.png" alt="" height="25" />
                    </div>
                  ) : uploadDraftIcon ? (
                    <div className="preview-mini-cookie">
                      <img src={uploadDraftIcon} alt="" width="20" height="20" />
                    </div>
                  ) : (
                    <ImageUploading value={imagesIcon} dataURLKey="Icon_url">
                      {({ imageList }) => (
                        <Fragment>
                          {imageList.map((image, index) => (
                            <div className="preview-mini-cookie" key={index}>
                              <img src={image['Icon_url']} alt="" width="20" height="20" />
                            </div>
                          ))}
                        </Fragment>
                      )}
                    </ImageUploading>
                  )}
                </div>
              )}
            </div>
          </PreviewBoxFullTop>
        </Fragment>
      </BannerBox>
    )
  }
  const handleChangeColor = (props, number) => {
    let convert = props.rgb.a.toString().split('.')[1]
      ? props.rgb.a.toString().split('.')[1]
      : '100'
    if (number === 1) {
      setColorBackground(calculator(props.hex, convert))
    } else if (number === 2) {
      setColorDesc(calculator(props.hex, convert))
    } else if (number === 3) {
      setColorFont(calculator(props.hex, convert))
    } else if (number === 4) {
      setAcceptButtonBG(calculator(props.hex, convert))
    } else if (number === 5) {
      setAcceptTextColor(calculator(props.hex, convert))
    } else if (number === 6) {
      setDeclineButtonBorder(calculator(props.hex, convert))
    } else if (number === 7) {
      setDeclineTextColor(calculator(props.hex, convert))
    } else if (number === 8) {
      setLinkTextColor(calculator(props.hex, convert))
    } else if (number === 9) {
      setSettingTextColor(calculator(props.hex, convert))
    } else if (number === 10) {
      setSettingButtonBorder(calculator(props.hex, convert))
    }
  }
  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null
  }

  const showColorPicker = number => {
    if (number === 1) {
      setIsShowColorPickerBG(true)
    } else if (number === 2) {
      setIsShowColorPickerDESC(true)
    } else if (number === 3) {
      setIsShowColorPickerFT(true)
    } else if (number === 4) {
      setIsShowColorPickerAcceptButtonBG(true)
    } else if (number === 5) {
      setIsShowColorPickerAcceptTextColor(true)
    } else if (number === 6) {
      setIsShowColorPickerdeclineButtonBorder(true)
    } else if (number === 7) {
      setIsShowColorPickerDeclineTextColor(true)
    } else if (number === 8) {
      setIsShowColorPickerLinkTextColor(true)
    } else if (number === 9) {
      setIsShowColorPickerSettingTextColor(true)
    } else if (number === 10) {
      setIsShowColorPickerSettingBorder(true)
    }
  }

  const handleChangeRange = e => {
    let target = e.target
    const min = target.min
    const max = target.max
    const val = target.value
    target.style.backgroundSize = ((val - min) * 100) / (max - min) + '% 100%'
    setRangeOpacity(e.target.value)
  }

  const closePopup = () => {
    setIsShowColorPickerBG(false)
    setIsShowColorPickerFT(false)
    setIsShowColorPickerDESC(false)
    setIsShowColorPickerAcceptButtonBG(false)
    setIsShowColorPickerAcceptTextColor(false)
    setIsShowColorPickerdeclineButtonBorder(false)
    setIsShowColorPickerDeclineTextColor(false)
    setIsShowColorPickerLinkTextColor(false)
    setIsShowColorPickerSettingTextColor(false)
    setIsShowColorPickerSettingBorder(false)
  }
  const handleChangeIconBanner = e => {
    setIconBanner(e.target.value)
  }
  const handleOptionDetectLang = e => {
    setOptionDetectLang(e.target.value)
  }
  const handleOptionUploadIcon = e => {
    setShowIcon(e.target.value === 'true' ? true : false)
  }

  const onChange = async imageList => {
    if (imageList.length > 0) {
      let obj = {
        consent_id: id,
        logo: imageList[0].file,
        type: 'main'
      }
      const { data, status } = await uploadLogo(obj)
      if (status === 200) {
        let random = Math.floor(100000 + Math.random() * 900000)
        if (data.data[0].cdn_url) {
          setUploadDraftLogo(data.data[0].cdn_url + `?cache=${random}`)
        }
      }
    }

    setImages(imageList)
    setUploadErrors(false)
  }
  const onChangeIcon = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      let obj = {
        consent_id: id,
        logo: imageList[0].file,
        type: 'small'
      }
      const { data, status } = await uploadLogo(obj)
      if (status === 200) {
        let random = Math.floor(100000 + Math.random() * 900000)
        if (data.data[0].cdn_url) {
          setUploadDraftIcon(data.data[0].cdn_url + `?cache=${random}`)
        }
      }
    }
    setImagesIcon(imageList)
    setUploadIconErrors(false)
  }

  const onError = (errors, files) => {
    setUploadErrors(true)
  }
  const onIconError = (errors, files) => {
    setUploadIconErrors(true)
  }
  const Imagesuploading = () => {
    return (
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={1}
        dataURLKey="data_url"
        maxFileSize={1000000}
        acceptType={['jpg', 'png']}
        onError={onError}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          error
        }) => (
          <div className="upload__image-wrapper dropzone dz-clickable">
            <div
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
              className="dz-message needsclick text-center m-0"
            >
              <div>
                <p className="fs-14 m-3 text-primary">
                  <i className="mdi mdi-plus"></i> {i18n[lang].clickToSelectLogoFile}
                </p>
              </div>
            </div>

            {imageList.map((image, index) => (
              <div key={index} className="image-item text-center pt-0 p-3">
                <img className="img-logo-footer" src={image['data_url']} alt="" />
                <div className="image-item__btn-wrapper text-center pt-3">
                  <button className="btn btn-primary m-2" onClick={() => onImageUpdate(index)}>
                    Update
                  </button>
                  <button
                    className="btn btn-primary m-2"
                    onClick={() => {
                      onImageRemove(index)
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}

            {/* {error.map((errors, index) => (
              <div key={index}>
              {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
              {errors.acceptType && <span>Your selected file type is not allow</span>}
              {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
            </div>
            ))} */}
          </div>
        )}
      </ImageUploading>
    )
  }

  const IconUploading = () => {
    return (
      <ImageUploading
        multiple
        value={imagesIcon}
        onChange={onChangeIcon}
        maxNumber={maxNumberIcon}
        dataURLKey="Icon_url"
        maxFileSize={1000000}
        acceptType={['jpg', 'png']}
        onError={onIconError}
      >
        {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
          <div className="upload__image-wrapper dropzone dz-clickable">
            <div
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
              className="dz-message needsclick text-center m-0"
            >
              <div>
                <p className="fs-14 m-3 text-primary">
                  <i className="mdi mdi-plus"></i> {i18n[lang].clickToSelectIconFile}
                </p>
              </div>
            </div>

            {imageList.map((icons, index) => (
              <div key={index} className="image-item text-center pt-0 p-3">
                <img className="img-logo-footer" src={icons['Icon_url']} alt="" />
                <div className="image-item__btn-wrapper text-center">
                  <button className="btn btn-primary m-2" onClick={() => onImageUpdate(index)}>
                    Update
                  </button>
                  <button className="btn btn-primary m-2" onClick={() => onImageRemove(index)}>
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    )
  }

  const positionBanner = position => {
    let obj = {}
    setBannerPosition(position)
    if (position === 'default') {
      setConfigPosition({})
      // bottom
    } else if (position === 'popup') {
      obj = {
        bottom: '30',
        padding: '23',
        maxWidth: '50%'
      }
      setConfigPosition(obj)
    } else if (position === 'fullTop') {
      obj = {
        top: '0%'
      }
      setConfigPosition(obj)
    } else if (position === 'bottomLeft') {
      obj = {
        bottom: '3',
        padding: '2',
        maxWidth: '100%',
        left: '0'
      }
      setConfigPosition(obj)
    } else if (position === 'topLeft') {
      obj = {
        padding: '2',
        maxWidth: '100%',
        top: '0',
        left: '0'
      }
      setConfigPosition(obj)
    } else if (position === 'bottomRight') {
      obj = {
        bottom: '3',
        padding: '2',
        maxWidth: '100%',
        top: '0',
        left: '0'
      }
      setConfigPosition(obj)
    } else if (position === 'topRight') {
      obj = {
        bottom: '3',
        padding: '2',
        maxWidth: '100%',
        top: '0',
        left: '0'
      }
      setConfigPosition(obj)
    } else {
      obj = {
        bottom: '0',
        padding: '0',
        maxWidth: '100%'
      }
      setConfigPosition(obj)
    }
  }

  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  }

  const handleClose = () => {
    setIsShowColorPickerBG(false)
  }
  const handleCookieNameDetectLang = e => {
    setCookieNameDetectLang(e.target.value)
  }

  return (
    <Fragment>
      <SecondaryMenuComponent id={props.match.params.id} langFn={lang} />
      <div className="main-content">
        {isSaveConfig.show && (
          <div
            className={`alert ${
              isSaveConfig.status ? 'alert-success' : 'alert-danger'
            }  alert-dismissible alert-solid alert-label-icon fade show xs-alert-center`}
            role="alert"
          >
            {isSaveConfig.status ? (
              <i className="ri-check-double-line label-icon"></i>
            ) : (
              <i className="ri-error-warning-line label-icon"></i>
            )}

            {isSaveConfig.msg}
            <button
              type="button"
              className="btn-close btn-close-white"
              // data-bs-dismiss="alert"
              // aria-label="Close"
              onClick={() => setIsSaveConfig({ show: false, status: true, msg: '' })}
            ></button>
          </div>
        )}

        <div className="page-content">
          <div className="container-fluid">
            <div className="card fixed_header">
              <div className="card-body py-2">
                <div className="row">
                  <div className="col-7">
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="card-title mb-0 py-2">{i18n[lang].bannerManagement} </h6>
                      </div>
                      <div className="col-6">
                        <span>{i18n[lang].bannerLanguage}</span>
                        <div className="btn-group px-4">
                          <button
                            className="btn btn-light dropdown-toggle"
                            type="button"
                            // id="defaultDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="true"
                            aria-expanded="true"
                          >
                            <img
                              src={`/assets/images/${detailLang}-Flag-icon.png`}
                              alt=""
                              className="img-sm-px mx-1"
                            />{' '}
                            {detailLang === 'th' ? 'ภาษาไทย' : 'English'}
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                            {detailLang === 'th' ? (
                              <li>
                                <a className="dropdown-item" onClick={() => setDetailLang('en')}>
                                  <img
                                    src="/assets/images/EN-Flag-icon.png"
                                    alt=""
                                    className="img-sm-px mx-1"
                                  />{' '}
                                  English
                                </a>
                              </li>
                            ) : (
                              <li>
                                <a className="dropdown-item" onClick={() => setDetailLang('th')}>
                                  <img
                                    src="/assets/images/TH-Flag-icon.png"
                                    alt=""
                                    className="img-sm-px mx-1"
                                  />{' '}
                                  ภาษาไทย
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    {' '}
                    <button
                      onClick={() => (saveConfigBanner(), setIsShowLoading(true))}
                      className="btn btn-primary btn-label  float-end btn-load "
                      disabled={
                        initDetail &&
                        initDetail.permission_consents &&
                        initDetail.permission_consents[0] &&
                        initDetail.permission_consents[0].role &&
                        initDetail.permission_consents[0].role === 'viewer'
                          ? isShowLoading
                          : isShowLoading
                      }
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <i className="label-icon align-middle fs-16 me-2">
                            {isShowLoading ? (
                              <Fragment>
                                {' '}
                                <span
                                  className="spinner-border flex-shrink-0"
                                  role="status"
                                ></span>{' '}
                              </Fragment>
                            ) : (
                              <i className="mdi mdi-tray-arrow-down label-icon align-middle fs-16 me-2"></i>
                            )}
                          </i>
                        </div>
                        <div className="flex-grow-1">{i18n[lang].saveSettings}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-4">
                <AccordionBox>
                  <div className="accordion" id="accordion-banner">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingzero">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsezero"
                          aria-expanded="true"
                          aria-controls="collapsezero"
                        >
                          {i18n[lang].bannerLanguage}
                        </button>
                      </h2>
                      <div
                        id="collapsezero"
                        className="accordion-collapse collapse show"
                        aria-labelledby="collapsezero"
                        data-bs-parent="#accordion-banner"
                      >
                        <div className="accordion-body pt-0">
                          <div className="row my-3">
                            <div className="col-md-6">
                              <p className="f-14 text-dark">{i18n[lang].bannerDefaultLanguage}</p>
                            </div>

                            <div className="col-md-6  text-right">
                              <div className="btn-group px-4">
                                <button
                                  className="btn btn-light dropdown-toggle"
                                  type="button"
                                  // id="defaultDropdown"
                                  data-bs-toggle="dropdown"
                                  data-bs-auto-close="true"
                                  aria-expanded="true"
                                  disabled={
                                    initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer'
                                      ? true
                                      : false
                                  }
                                >
                                  <img
                                    src={`/assets/images/${bannerLang}-Flag-icon.png`}
                                    alt=""
                                    className="img-sm-px mx-1"
                                  />{' '}
                                  {bannerLang === 'th' ? 'ภาษาไทย' : 'English'}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                  {bannerLang === 'th' ? (
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => setBannerLang('en')}
                                      >
                                        <img
                                          src="/assets/images/EN-Flag-icon.png"
                                          alt=""
                                          className="img-sm-px mx-1"
                                        />{' '}
                                        English
                                      </a>
                                    </li>
                                  ) : (
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => setBannerLang('th')}
                                      >
                                        <img
                                          src="/assets/images/TH-Flag-icon.png"
                                          alt=""
                                          className="img-sm-px mx-1"
                                        />{' '}
                                        ภาษาไทย
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className={`row my-3 ${packageId === 1 ? 'disable-div' : ''}`}>
                            <div className="col-md-10">
                              <p className="fs-14 text-dark">
                                {packageId === 1 ? <MdLock /> : ''}{' '}
                                {i18n[lang].automaticLanguageDetection}
                              </p>
                            </div>
                            <div className="col-md-2  text-right">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={autolang}
                                  onChange={() => {
                                    setAutolang(!autolang)
                                  }}
                                  disabled={
                                    (initDetail &&
                                      initDetail.permission_consents &&
                                      initDetail.permission_consents[0] &&
                                      initDetail.permission_consents[0].role &&
                                      initDetail.permission_consents[0].role === 'viewer') ||
                                    packageId === 1
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                            {autolang && (
                              <div className="col-md-12">
                                <RadioBox>
                                  <div className="form-check form-radio-outline form-radio-primary mt-3 p-0 px-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="OptionDetectLang1"
                                      id="OptionDetectLang1"
                                      value="default"
                                      onChange={handleOptionDetectLang}
                                      checked={optionDetectLang === 'default'}
                                    />
                                    <label className="form-check-label" htmlFor="formCheck1">
                                      <p className="fs-14 m-0 px-2 text-dark">
                                        {i18n[lang].detectLanguageFromHtml}
                                        <br /> {i18n[lang].detectLanguageFromHtmlTH}
                                      </p>
                                    </label>
                                  </div>
                                  {optionDetectLang === 'default' && (
                                    <div className="fs-12 text-muted">
                                      {i18n[lang].specifyLanguageInTag}
                                    </div>
                                  )}

                                  <div className="form-check form-radio-outline form-radio-primary mt-3 p-0 px-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="OptionDetectLang2"
                                      id="OptionDetectLang2"
                                      value="cookie"
                                      onChange={handleOptionDetectLang}
                                      checked={optionDetectLang === 'cookie'}
                                    />
                                    <label className="form-check-label" htmlFor="formCheck1">
                                      <p className="fs-14 m-0 px-2 text-dark">
                                        {i18n[lang].detectLanguageFromCookies}
                                      </p>
                                    </label>
                                  </div>
                                </RadioBox>
                                {optionDetectLang === 'cookie' && (
                                  <div>
                                    <p className="fs-12">
                                      {i18n[lang].enterCookieNameValue}
                                      <br />
                                      {i18n[lang].enterCookieName}
                                    </p>
                                    <input
                                      type="text"
                                      className={`form-control  ${
                                        optionDetectLang === 'cookie' && validdteNameDetectLang
                                          ? 'is-invalid'
                                          : ''
                                      }`}
                                      value={cookieNameDetectLang}
                                      placeholder={i18n[lang].cookieName}
                                      onChange={e => setCookieNameDetectLang(e.target.value)}
                                    />
                                    {optionDetectLang === 'cookie' && validdteNameDetectLang ? (
                                      <p className="text-Danger mt-2">
                                        {i18n[lang].enterCookieNames}
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingseven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseseven"
                          aria-expanded="false"
                          aria-controls="collapseseven"
                        >
                          {i18n[lang].bannerFormat}
                        </button>
                      </h2>
                      <div
                        id="collapseseven"
                        className="accordion-collapse collapse "
                        aria-labelledby="collapseseven"
                        data-bs-parent="#accordion-banner"
                      >
                        <div className="accordion-body pt-0">
                          <div className="row my-3">
                            <div className="col-4 px-1 my-2">
                              <div
                                className={`card mb-0 ${
                                  bannerPosition === 'default' ? 'border card-border-primary' : ''
                                }`}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? positionBanner('default')
                                    : ''
                                }
                              >
                                <div className="card-body p-0 text-center">
                                  <img
                                    src="/assets/images/imgDefault.png"
                                    alt=""
                                    className="w-100 p-1"
                                  />
                                </div>
                                <div className="card-footer border-0 p-0 px-3 text-center">
                                  {i18n[lang].default}
                                </div>
                              </div>
                            </div>
                            <div className="col-4 px-1 my-2">
                              <div
                                className={`card mb-0 ${
                                  bannerPosition === 'bottom' ? 'border card-border-primary' : ''
                                }`}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? positionBanner('bottom')
                                    : ''
                                }
                              >
                                <div className="card-body p-0">
                                  <img
                                    src="/assets/images/fullButton.png"
                                    alt=""
                                    className="w-100 p-1"
                                  />
                                </div>
                                <div className="card-footer border-0 p-0 px-3 text-center">
                                  {i18n[lang].fullBottom}
                                </div>
                              </div>
                            </div>
                            <div className="col-4 px-1 my-2">
                              <div
                                className={`card mb-0 ${
                                  bannerPosition === 'fullTop' ? 'border card-border-primary' : ''
                                }`}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? positionBanner('fullTop')
                                    : ''
                                }
                              >
                                <div className="card-body p-0">
                                  <img
                                    src="/assets/images/fullTop.png"
                                    alt=""
                                    className="w-100 p-1"
                                  />
                                </div>
                                <div className="card-footer border-0 p-0 px-3 text-center">
                                  {i18n[lang].fullTop}
                                </div>
                              </div>
                            </div>
                            <div className="col-4 px-1 my-2">
                              <div
                                className={`card mb-0 ${
                                  bannerPosition === 'popup' ? 'border card-border-primary' : ''
                                }`}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? positionBanner('popup')
                                    : ''
                                }
                              >
                                <div className="card-body p-0">
                                  <img
                                    src="/assets/images/popup.png"
                                    alt=""
                                    className="w-100 p-1"
                                  />
                                </div>
                                <div className="card-footer border-0 p-0 px-3 text-center">
                                  {i18n[lang].popup}
                                </div>
                              </div>
                            </div>

                            <div className="col-4 px-1 my-2">
                              <div
                                className={`card mb-0 ${
                                  bannerPosition === 'bottomLeft'
                                    ? 'border card-border-primary'
                                    : ''
                                }`}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? positionBanner('bottomLeft')
                                    : ''
                                }
                              >
                                <div className="card-body p-0">
                                  <img
                                    src="/assets/images/bottomLeft.png"
                                    alt=""
                                    className="w-100 p-1"
                                  />
                                </div>
                                <div className="card-footer border-0 p-0 px-3 text-center">
                                  {i18n[lang].bottomLeft}
                                </div>
                              </div>
                            </div>
                            <div className="col-4 px-1 my-2">
                              <div
                                className={`card mb-0 ${
                                  bannerPosition === 'topLeft' ? 'border card-border-primary' : ''
                                }`}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? positionBanner('topLeft')
                                    : ''
                                }
                              >
                                <div className="card-body p-0">
                                  <img
                                    src="/assets/images/topLeft.png"
                                    alt=""
                                    className="w-100 p-1"
                                  />
                                </div>
                                <div className="card-footer border-0 p-0 px-3 text-center">
                                  {i18n[lang].topLeft}
                                </div>
                              </div>
                            </div>
                            <div className="col-4 px-1 my-2">
                              <div
                                className={`card mb-0 ${
                                  bannerPosition === 'bottomRight'
                                    ? 'border card-border-primary'
                                    : ''
                                }`}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? positionBanner('bottomRight')
                                    : ''
                                }
                              >
                                <div className="card-body p-0">
                                  <img
                                    src="/assets/images/bottomRight.png"
                                    alt=""
                                    className="w-100 p-1"
                                  />
                                </div>
                                <div className="card-footer border-0 p-0 px-3 text-center">
                                  {i18n[lang].bottomRight}
                                </div>
                              </div>
                            </div>
                            <div className="col-4 px-1 my-2">
                              <div
                                className={`card mb-0 ${
                                  bannerPosition === 'topRight' ? 'border card-border-primary' : ''
                                }`}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? positionBanner('topRight')
                                    : ''
                                }
                              >
                                <div className="card-body p-0">
                                  <img
                                    src="/assets/images/topRight.png"
                                    alt=""
                                    className="w-100 p-1"
                                  />
                                </div>
                                <div className="card-footer border-0 p-0 px-3 text-center">
                                  {i18n[lang].topRight}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          {i18n[lang].text}
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordion-banner"
                      >
                        <div className="accordion-body pt-0">
                          <div className="row my-3">
                            <div className="col-md-10">
                              <p className="f-14 text-dark">{i18n[lang].displayTitle}</p>
                            </div>

                            <div className="col-md-2  text-right">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={showTitle}
                                  onChange={() => {
                                    setShowTitle(!showTitle)
                                    // texing.showTitle = !showTitle;
                                  }}
                                  disabled={
                                    initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer'
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row my-3">
                            <div className="col-md-12">
                              <p className="f-14 text-dark">{i18n[lang].titleText}</p>
                            </div>

                            <div className="col-md-12  text-right">
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={detailLang === 'th' ? titleTH : titleEN}
                                  onChange={e => {
                                    detailLang === 'th'
                                      ? setTitleTH(e.target.value)
                                      : setTitleEN(e.target.value)
                                  }}
                                  disabled={
                                    initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer'
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row my-3">
                            <div className="col-md-12">
                              <p className="f-14 text-dark">{i18n[lang].bodyText}</p>
                            </div>

                            <div className="col-md-12  text-right">
                              <div>
                                <textarea
                                  className="form-control"
                                  value={detailLang === 'th' ? descTH : descEN}
                                  onChange={e => {
                                    detailLang === 'th'
                                      ? setDescTH(e.target.value)
                                      : setDescEN(e.target.value)
                                  }}
                                  rows="3"
                                  disabled={
                                    initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer'
                                      ? true
                                      : false
                                  }
                                ></textarea>
                              </div>
                            </div>
                            <div
                              className={`col-md-12 mt-3 ${packageId === 1 ? 'disable-div' : ''}`}
                            >
                              <p className={`text-dark `}>
                                {' '}
                                {packageId === 1 ? <MdLock /> : ''} {i18n[lang].titleFontSize}
                              </p>
                              <div className={`input-group`}>
                                <input
                                  type="number"
                                  className="form-control border-right-0"
                                  aria-label="sizeheader"
                                  aria-describedby="sizeheader"
                                  value={titleFontSize}
                                  onChange={e =>
                                    e.target.value.length < 3
                                      ? setTitleFontSize(e.target.value)
                                      : ''
                                  }
                                  disabled={packageId === 1 ? true : false}
                                />
                                <button
                                  className="btn btn-success px-4 border-left-0"
                                  type="button"
                                  id="sizeheader"
                                >
                                  px
                                </button>
                              </div>
                            </div>
                            <div
                              className={`col-md-12 mt-3 ${packageId === 1 ? 'disable-div' : ''}`}
                            >
                              <p className="text-dark">
                                {packageId === 1 ? <MdLock /> : ''} {i18n[lang].bodyFontSize}
                              </p>
                              <div className="input-group">
                                <input
                                  type="number"
                                  className="form-control border-right-0"
                                  aria-label="sizetext"
                                  aria-describedby="sizetext"
                                  value={fontSize}
                                  onChange={e =>
                                    e.target.value.length < 3 ? setFontSize(e.target.value) : ''
                                  }
                                  disabled={packageId === 1 ? true : false}
                                />
                                <button
                                  className="btn btn-success px-4 border-left-0"
                                  type="button"
                                  id="sizetext"
                                >
                                  px
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          {i18n[lang].cookiePolicy}
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordion-banner"
                      >
                        <div className="accordion-body pt-0">
                          <div name="input-TextPolicy" className="row my-3">
                            <div className="col-md-12">
                              <p className="f-14 text-dark">{i18n[lang].cookiePolicyText}</p>
                              <select
                                className="form-select mb-3"
                                aria-label="Default select example"
                                onChange={e => customTextPolicy(e)}
                                value={customPolicy ? '1' : '2'}
                                disabled={
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role === 'viewer'
                                    ? true
                                    : false
                                }
                              >
                                <option value="1">{i18n[lang].cookiePolicy}</option>
                                <option value="2">{i18n[lang].customize}</option>
                              </select>
                            </div>

                            {!customPolicy && (
                              <div className="col-md-12 ">
                                <p className="f-14 text-dark">{i18n[lang].policyName}</p>
                                <div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={detailLang === 'th' ? textPolicyTH : textPolicyEN}
                                    onChange={e => {
                                      detailLang === 'th'
                                        ? setTextPolicyTH(e.target.value)
                                        : setTextPolicyEN(e.target.value)
                                    }}
                                    disabled={
                                      initDetail &&
                                      initDetail.permission_consents &&
                                      initDetail.permission_consents[0] &&
                                      initDetail.permission_consents[0].role &&
                                      initDetail.permission_consents[0].role === 'viewer'
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          <div name="input-UrlPolicy" className="row my-3">
                            <div className="col-md-12">
                              <p className="f-14 text-dark">{i18n[lang].cookiePolicyLink}</p>
                            </div>

                            <div className="col-md-12  text-right">
                              <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">
                                  <i className="mdi mdi-link label-icon"></i>
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="http://www.example.com"
                                  aria-label="url"
                                  value={urlPolicy}
                                  onChange={e => {
                                    setUrlPolicy(e.target.value)
                                  }}
                                  disabled={
                                    initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer'
                                      ? true
                                      : false
                                  }
                                />
                                {inValidateLinkName && (
                                  <span className="invalid-feedback-err">
                                    {i18n[lang].urlFormatError}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingfour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsefour"
                          aria-expanded="false"
                          aria-controls="collapsefour"
                        >
                          {i18n[lang].colorsAndButtonsSettings}
                        </button>
                      </h2>
                      <div
                        id="collapsefour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingfour"
                        data-bs-parent="#accordion-banner"
                      >
                        <div className="accordion-body pt-0">
                          <div name="head-text" className="row my-3">
                            <div className="col-md-12">
                              <p className="f-14 text-dark font-weight-bold">
                                {i18n[lang].basicBannerStyleSettings}
                              </p>
                            </div>
                          </div>
                          <div name="cl-PickerBG" className="row my-3">
                            <div className="col-md-6">
                              <p className="f-14 text-dark">{i18n[lang].backgroundColor}</p>
                            </div>

                            <div className="col-md-6 d-flex text-right">
                              <div
                                className={`react_color`}
                                style={{ backgroundColor: colorBackground }}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? showColorPicker(1)
                                    : ''
                                }
                                // onFocus={e => setIsShowColorPickerBG(true)}
                                // onBlur={e => setIsShowColorPickerBG(false)}
                              />
                              {isShowColorPickerBG && (
                                <div className="popover">
                                  <div className="pop_cover">
                                    <div style={cover} onClick={closePopup} />
                                    <SketchPicker
                                      color={colorBackground}
                                      onChange={e => handleChangeColor(e, 1)}
                                    />
                                  </div>
                                </div>
                              )}
                              <input
                                className="form-control px-2 color-input"
                                placeholder="#FFFFFF"
                                value={colorBackground}
                                onChange={e => {
                                  let val = e.target.value.match('#')
                                    ? e.target.value
                                    : '#' + e.target.value
                                  if (e.target.value.length < 10 && hexChars.test(e.target.value)) {
                                    setColorBackground(val)
                                  }
                                }}
                                disabled={
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role === 'viewer'
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                          <div name="cl-ColorFont" className="row my-3">
                            <div className="col-md-6">
                              <p className="f-14 text-dark">{i18n[lang].fontColorTitle}</p>{' '}
                            </div>
                            <div className="col-md-6 d-flex text-right">
                              <div
                                className={`react_color`}
                                style={{ backgroundColor: colorFont }}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? showColorPicker(3)
                                    : ''
                                }
                              />
                              {isShowColorPickerFT && (
                                <div className="popover">
                                  <div className="pop_cover">
                                    <div style={cover} onClick={closePopup} />
                                    <SketchPicker
                                      color={colorFont}
                                      onChange={e => handleChangeColor(e, 3)}
                                    />
                                  </div>
                                </div>
                              )}
                              <input
                                className="form-control px-2 color-input"
                                placeholder="#FFFFFF"
                                value={colorFont}
                                onChange={e => {
                                  let val = e.target.value.match('#')
                                    ? e.target.value
                                    : '#' + e.target.value
                                  if (e.target.value.length < 10 && hexChars.test(e.target.value)) {
                                    setColorFont(val)
                                  }
                                }}
                                disabled={
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role === 'viewer'
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                          <div name="cl-Desc" className="row my-3">
                            <div className="col-md-6">
                              <p className="f-14 text-dark">{i18n[lang].fontColorText}</p>{' '}
                            </div>
                            <div className="col-md-6 d-flex text-right">
                              <div
                                className={`react_color`}
                                style={{ backgroundColor: colorDesc }}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? showColorPicker(2)
                                    : ''
                                }
                              />
                              {isShowColorPickerDESC && (
                                <div className="popover">
                                  <div className="pop_cover">
                                    <div style={cover} onClick={() => closePopup()} />
                                    <SketchPicker
                                      color={colorDesc}
                                      onChange={e => handleChangeColor(e, 2)}
                                    />
                                  </div>
                                </div>
                              )}
                              <input
                                className="form-control px-2 color-input"
                                placeholder="#FFFFFF"
                                value={colorDesc}
                                onChange={e => {
                                  let val = e.target.value.match('#')
                                    ? e.target.value
                                    : '#' + e.target.value
                                  if (e.target.value.length < 10 && hexChars.test(e.target.value)) {
                                    setColorDesc(val)
                                  }
                                }}
                                disabled={
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role === 'viewer'
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>

                          <hr />
                          <div name="head-text-policy" className="row my-3">
                            <div className="col-md-12">
                              <p className="f-14 text-dark font-weight-bold">
                                {i18n[lang].cookiePolicyButtonSettings}
                              </p>
                            </div>
                          </div>
                          <div
                            name="btn-cl-LinkTextColor"
                            className={`row my-3 ${packageId === 1 ? 'disable-div' : ''}`}
                          >
                            <div className="col-md-6">
                              <p className="f-14 text-dark">
                                {' '}
                                {packageId === 1 ? <MdLock /> : ''}{' '}
                                {i18n[lang].cookiePolicyButtonColor}
                              </p>
                            </div>

                            <div className="col-md-6 d-flex text-right">
                              <div
                                className={`react_color`}
                                style={{ backgroundColor: linkTextColor }}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? packageId !== 1
                                      ? showColorPicker(8)
                                      : ''
                                    : ''
                                }
                                onFocus={e => setIsShowColorPickerLinkTextColor(true)}
                                onBlur={e => setIsShowColorPickerLinkTextColor(false)}
                              />
                              {isShowColorPickerLinkTextColor && (
                                <div className="popover">
                                  <div className="pop_cover">
                                    <div style={cover} onClick={() => closePopup()} />
                                    <SketchPicker
                                      color={linkTextColor}
                                      onChange={e => handleChangeColor(e, 8)}
                                    />
                                  </div>
                                </div>
                              )}
                              <input
                                className="form-control px-2 color-input"
                                placeholder="#FFFFFF"
                                value={linkTextColor}
                                onChange={e => {
                                  let val = e.target.value.match('#')
                                    ? e.target.value
                                    : '#' + e.target.value
                                  if (e.target.value.length < 10 && hexChars.test(e.target.value)) {
                                    if (packageId !== 1) {
                                      setLinkTextColor(val)
                                    }
                                  }
                                }}
                                disabled={
                                  (initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer') ||
                                  packageId === 1
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                          <hr />
                          <div name="head-text-setting" className="row my-3">
                            <div className="col-md-12">
                              <p className="f-14 text-dark font-weight-bold">
                                {i18n[lang].cookieSettingsButtonSettings}
                              </p>
                            </div>
                          </div>

                          <div name="btn-Show-Setting-Border" className="row my-3">
                            <div className="col-md-10">
                              <p className="f-14 text-dark m-0">
                                {i18n[lang].displayCookieSettingsButton}
                              </p>
                            </div>

                            <div className="col-md-2  text-right m-0">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={settingCustomButton}
                                  onChange={() => {
                                    setSettingCustomButton(!settingCustomButton)
                                  }}
                                  disabled={
                                    initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer'
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            name="btn-cl-setting-border"
                            className={`row my-3 ${packageId === 1 ? 'disable-div' : ''}`}
                          >
                            <div className="col-md-6">
                              <p className="f-14 text-dark">
                                {packageId === 1 ? <MdLock /> : ''}{' '}
                                {i18n[lang].cookieSettingsButtonColor}{' '}
                              </p>
                            </div>

                            <div className="col-md-6 d-flex text-right">
                              <div
                                className={`react_color`}
                                style={{ backgroundColor: settingButtonBorder }}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? settingCustomButton
                                      ? packageId !== 1
                                        ? showColorPicker(10)
                                        : ''
                                      : ''
                                    : ''
                                }
                                onFocus={e => setIsShowColorPickerSettingBorder(true)}
                                onBlur={e => setIsShowColorPickerSettingBorder(false)}
                              />
                              {isShowColorPickerSettingBorder && (
                                <div className="popover">
                                  <div className="pop_cover">
                                    <div style={cover} onClick={() => closePopup()} />
                                    <SketchPicker
                                      color={settingButtonBorder}
                                      onChange={e => handleChangeColor(e, 10)}
                                    />
                                  </div>
                                </div>
                              )}
                              <input
                                className="form-control px-2 color-input"
                                placeholder="#1298BE"
                                value={settingButtonBorder}
                                onChange={e => {
                                  let val = e.target.value.match('#')
                                    ? e.target.value
                                    : '#' + e.target.value
                                  if (e.target.value.length < 10 && hexChars.test(e.target.value)) {
                                    if (packageId !== 1) {
                                      setSettingButtonBorder(val)
                                    }
                                  }
                                }}
                                disabled={
                                  (initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer') ||
                                  !settingCustomButton ||
                                  packageId === 1
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                          <div
                            name="btn-cl-setting"
                            className={`row my-3 ${packageId === 1 ? 'disable-div' : ''}`}
                          >
                            <div className="col-md-6">
                              <p className="f-14 text-dark">
                                {packageId === 1 ? <MdLock /> : ''}{' '}
                                {i18n[lang].cookieSettingsButtonTextColor}
                              </p>
                            </div>

                            <div className="col-md-6 d-flex text-right">
                              <div
                                className={`react_color`}
                                style={{ backgroundColor: settingTextColor }}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? packageId !== 1
                                      ? showColorPicker(9)
                                      : ''
                                    : ''
                                }
                                onFocus={e => setIsShowColorPickerSettingTextColor(true)}
                                onBlur={e => setIsShowColorPickerSettingTextColor(false)}
                              />
                              {isShowColorPickerSettingTextColor && (
                                <div className="popover">
                                  <div className="pop_cover">
                                    <div style={cover} onClick={() => closePopup()} />
                                    <SketchPicker
                                      color={settingTextColor}
                                      onChange={e => handleChangeColor(e, 9)}
                                    />
                                  </div>
                                </div>
                              )}
                              <input
                                className="form-control px-2 color-input"
                                placeholder="#FFFFFF"
                                value={settingTextColor}
                                onChange={e => {
                                  let val = e.target.value.match('#')
                                    ? e.target.value
                                    : '#' + e.target.value
                                  if (e.target.value.length < 10 && hexChars.test(e.target.value)) {
                                    setSettingTextColor(val)
                                  }
                                }}
                                disabled={
                                  (initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer') ||
                                  packageId === 1
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                          <div
                            name="input-SettingTH"
                            className={`row my-3 ${packageId === 1 ? 'disable-div' : ''}`}
                          >
                            <div className="col-md-12">
                              <p className="f-14 text-dark">
                                {packageId === 1 ? <MdLock /> : ''}{' '}
                                {i18n[lang].cookieSettingsButtonText}
                              </p>
                            </div>

                            <div className="col-md-12  text-right">
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={detailLang === 'th' ? settingTH : settingEN}
                                  onChange={e => {
                                    detailLang === 'th'
                                      ? setSettingTH(e.target.value)
                                      : setSettingEN(e.target.value)
                                  }}
                                  disabled={
                                    (initDetail &&
                                      initDetail.permission_consents &&
                                      initDetail.permission_consents[0] &&
                                      initDetail.permission_consents[0].role &&
                                      initDetail.permission_consents[0].role === 'viewer') ||
                                    packageId === 1
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            name="input-AcceptTH"
                            className={`row my-3 ${packageId === 1 ? 'disable-div' : ''}`}
                          >
                            <div className="col-md-12">
                              <p className="f-14 text-dark">
                                {packageId === 1 ? <MdLock /> : ''} {i18n[lang].confirmMySettings}
                              </p>
                            </div>

                            <div className="col-md-12  text-right">
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={detailLang === 'th' ? buttonConfirmTH : buttonConfirmEN}
                                  onChange={e => {
                                    detailLang === 'th'
                                      ? setButtonConfirmTH(e.target.value)
                                      : setButtonConfirmEN(e.target.value)
                                  }}
                                  disabled={
                                    (initDetail &&
                                      initDetail.permission_consents &&
                                      initDetail.permission_consents[0] &&
                                      initDetail.permission_consents[0].role &&
                                      initDetail.permission_consents[0].role === 'viewer') ||
                                    packageId === 1
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <hr />

                          <div name="head-text-Accept" className="row my-3">
                            <div className="col-md-12">
                              <p className="f-14 text-dark font-weight-bold">
                                {i18n[lang].acceptButtonSettings}
                              </p>
                            </div>
                          </div>
                          <div
                            name="btn-cl-AcceptButtonBG"
                            className={`row my-3 ${packageId === 1 ? 'disable-div' : ''}`}
                          >
                            <div className="col-md-6">
                              <p className="f-14 text-dark">
                                {packageId === 1 ? <MdLock /> : ''}{' '}
                                {i18n[lang].acceptButtonBackgroundColor}
                              </p>
                            </div>

                            <div className="col-md-6 d-flex text-right">
                              <div
                                className={`react_color`}
                                style={{ backgroundColor: acceptButtonBG }}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? packageId !== 1
                                      ? showColorPicker(4)
                                      : ''
                                    : ''
                                }
                                onFocus={e => setIsShowColorPickerAcceptButtonBG(true)}
                                onBlur={e => setIsShowColorPickerAcceptButtonBG(false)}
                              />
                              {isShowColorPickerAcceptButtonBG && (
                                <div className="popover">
                                  <div className="pop_cover">
                                    <div style={cover} onClick={() => closePopup()} />
                                    <SketchPicker
                                      color={acceptButtonBG}
                                      onChange={e => handleChangeColor(e, 4)}
                                    />
                                  </div>
                                </div>
                              )}
                              <input
                                className="form-control px-2 color-input"
                                placeholder="#1298BE"
                                value={acceptButtonBG}
                                onChange={e => {
                                  let val = e.target.value.match('#')
                                    ? e.target.value
                                    : '#' + e.target.value
                                  if (e.target.value.length < 10 && hexChars.test(e.target.value)) {
                                    if (packageId !== 1) {
                                      setAcceptButtonBG(val)
                                    }
                                  }
                                }}
                                disabled={
                                  (initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer') ||
                                  packageId === 1
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                          <div
                            name="btn-cl-AcceptTextColor"
                            className={`row my-3 ${packageId === 1 ? 'disable-div' : ''}`}
                          >
                            <div className="col-md-6">
                              <p className="f-14 text-dark">
                                {packageId === 1 ? <MdLock /> : ''}{' '}
                                {i18n[lang].acceptButtonTextColor}
                              </p>
                            </div>

                            <div className="col-md-6 d-flex text-right">
                              <div
                                className={`react_color`}
                                style={{ backgroundColor: acceptTextColor }}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? packageId !== 1
                                      ? showColorPicker(5)
                                      : ''
                                    : ''
                                }
                                onFocus={e => setIsShowColorPickerAcceptTextColor(true)}
                                onBlur={e => setIsShowColorPickerAcceptTextColor(false)}
                              />
                              {isShowColorPickerAcceptTextColor && (
                                <div className="popover">
                                  <div className="pop_cover">
                                    <div style={cover} onClick={() => closePopup()} />
                                    <SketchPicker
                                      color={acceptTextColor}
                                      onChange={e => handleChangeColor(e, 5)}
                                    />
                                  </div>
                                </div>
                              )}
                              <input
                                className="form-control px-2 color-input"
                                placeholder="#FFFFFF"
                                value={acceptTextColor}
                                onChange={e => {
                                  let val = e.target.value.match('#')
                                    ? e.target.value
                                    : '#' + e.target.value
                                  if (e.target.value.length < 10 && hexChars.test(e.target.value)) {
                                    if (packageId !== 1) {
                                      setAcceptTextColor(val)
                                    }
                                  }
                                }}
                                disabled={
                                  (initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer') ||
                                  packageId === 1
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                          <div
                            name="input-AcceptTH"
                            className={`row my-3 ${packageId === 1 ? 'disable-div' : ''}`}
                          >
                            <div className="col-md-12">
                              <p className="f-14 text-dark">
                                {packageId === 1 ? <MdLock /> : ''} {i18n[lang].acceptButtonText}
                              </p>
                            </div>

                            <div className="col-md-12  text-right">
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={detailLang === 'th' ? acceptTH : acceptEN}
                                  onChange={e => {
                                    detailLang === 'th'
                                      ? setAcceptTH(e.target.value)
                                      : setAcceptEN(e.target.value)
                                  }}
                                  disabled={
                                    (initDetail &&
                                      initDetail.permission_consents &&
                                      initDetail.permission_consents[0] &&
                                      initDetail.permission_consents[0].role &&
                                      initDetail.permission_consents[0].role === 'viewer') ||
                                    packageId === 1
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <hr />

                          <div name="head-text-policy" className="row my-3">
                            <div className="col-md-7">
                              <p className="f-14 text-dark font-weight-bold">
                                {i18n[lang].declineButtonSettings}
                              </p>
                            </div>
                            <div className="col-md-3 text-right">{i18n[lang].displayButton}</div>
                            <div className="col-md-2 text-right">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={showReject}
                                  onChange={() => {
                                    setShowReject(!showReject)
                                  }}
                                  disabled={
                                    initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer'
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            name="btn-cl-declineButtonBorder"
                            className={`row my-3 ${packageId === 1 ? 'disable-div' : ''}`}
                          >
                            <div className="col-md-6">
                              <p className="f-14 text-dark">
                                {packageId === 1 ? <MdLock /> : ''} {i18n[lang].declineButtonColor}
                              </p>
                            </div>

                            <div className="col-md-6 d-flex text-right">
                              <div
                                className={`react_color`}
                                style={{ backgroundColor: declineButtonBorder }}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? showReject
                                      ? packageId !== 1
                                        ? showColorPicker(6)
                                        : ''
                                      : ''
                                    : ''
                                }
                                onFocus={e => setIsShowColorPickerdeclineButtonBorder(true)}
                                onBlur={e => setIsShowColorPickerdeclineButtonBorder(false)}
                              />
                              {isShowColorPickerdeclineButtonBorder && (
                                <div className="popover">
                                  <div className="pop_cover">
                                    <div style={cover} onClick={() => closePopup()} />
                                    <SketchPicker
                                      color={declineButtonBorder}
                                      onChange={e => handleChangeColor(e, 6)}
                                    />
                                  </div>
                                </div>
                              )}
                              <input
                                className="form-control px-2 color-input"
                                placeholder="#FFFFFF"
                                value={declineButtonBorder}
                                onChange={e => {
                                  let val = e.target.value.match('#')
                                    ? e.target.value
                                    : '#' + e.target.value
                                  if (e.target.value.length < 10 && hexChars.test(e.target.value)) {
                                    if (packageId !== 1) {
                                      setDeclineButtonBorder(val)
                                    }
                                  }
                                }}
                                disabled={
                                  (initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer') ||
                                  !showReject ||
                                  packageId === 1
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                          <div
                            name="btn-cl-DeclineTextColor"
                            className={`row my-3 ${packageId === 1 ? 'disable-div' : ''}`}
                          >
                            <div className="col-md-6">
                              <p className="f-14 text-dark">
                                {packageId === 1 ? <MdLock /> : ''}{' '}
                                {i18n[lang].declineButtonTextColor}
                              </p>
                            </div>

                            <div className="col-md-6 d-flex text-right">
                              <div
                                className={`react_color`}
                                style={{ backgroundColor: declineTextColor }}
                                onClick={() =>
                                  initDetail &&
                                  initDetail.permission_consents &&
                                  initDetail.permission_consents[0] &&
                                  initDetail.permission_consents[0].role &&
                                  initDetail.permission_consents[0].role !== 'viewer'
                                    ? showReject
                                      ? packageId !== 1
                                        ? showColorPicker(7)
                                        : ''
                                      : ''
                                    : ''
                                }
                                onFocus={e => setIsShowColorPickerDeclineTextColor(true)}
                                onBlur={e => setIsShowColorPickerDeclineTextColor(false)}
                              />
                              {isShowColorPickerDeclineTextColor && (
                                <div className="popover">
                                  <div className="pop_cover">
                                    <div style={cover} onClick={() => closePopup()} />
                                    <SketchPicker
                                      color={declineTextColor}
                                      onChange={e => handleChangeColor(e, 7)}
                                    />
                                  </div>
                                </div>
                              )}
                              <input
                                className="form-control px-2 color-input"
                                placeholder="#FFFFFF"
                                value={declineTextColor}
                                onChange={e => {
                                  let val = e.target.value.match('#')
                                    ? e.target.value
                                    : '#' + e.target.value
                                  if (e.target.value.length < 10 && hexChars.test(e.target.value)) {
                                    if (packageId !== 1) {
                                      setDeclineTextColor(val)
                                    }
                                  }
                                }}
                                disabled={
                                  (initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer') ||
                                  !showReject ||
                                  packageId === 1
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                          <div
                            name="input-DeclineTH"
                            className={`row my-3 ${packageId === 1 ? 'disable-div' : ''}`}
                          >
                            <div className="col-md-12">
                              <p className="f-14 text-dark">
                                {packageId === 1 ? <MdLock /> : ''} {i18n[lang].declineButtonText}
                              </p>
                            </div>

                            <div className="col-md-12  text-right">
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={detailLang === 'th' ? declineTH : declineEN}
                                  onChange={e => {
                                    detailLang === 'th'
                                      ? setDeclineTH(e.target.value)
                                      : setDeclineEN(e.target.value)
                                  }}
                                  disabled={
                                    (initDetail &&
                                      initDetail.permission_consents &&
                                      initDetail.permission_consents[0] &&
                                      initDetail.permission_consents[0].role &&
                                      initDetail.permission_consents[0].role === 'viewer') ||
                                    !showReject ||
                                    packageId === 1
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div name="btn-ShowCloseBtn" className="row my-3">
                            <div className="col-md-7">
                              <p className="f-14 text-dark m-0">{i18n[lang].closeBannerButtonX}</p>
                            </div>
                            <div className="col-md-3 text-right m-0">
                              {i18n[lang].displayButton}
                            </div>

                            <div className="col-md-2  text-right m-0">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={showCloseBtn}
                                  onChange={() => {
                                    setShowCloseBtn(!showCloseBtn)
                                  }}
                                  disabled={
                                    initDetail &&
                                    initDetail.permission_consents &&
                                    initDetail.permission_consents[0] &&
                                    initDetail.permission_consents[0].role &&
                                    initDetail.permission_consents[0].role === 'viewer'
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {packageId > 1 ? (
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingfive">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsefive"
                            aria-expanded="false"
                            aria-controls="collapsefive"
                          >
                            {i18n[lang].bannerLogo}
                          </button>
                        </h2>
                        <div
                          id="collapsefive"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingfive"
                          data-bs-parent="#accordion-banner"
                        >
                          <div className="accordion-body py-0">
                            <div className="row my-3">
                              <div className="col-md-10">
                                <p className="fs-14 text-dark">
                                  {i18n[lang].displayLogo} <br />
                                </p>
                              </div>

                              <div className="col-md-2  text-right">
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={showLogo}
                                    onChange={e => handleShowlogo(e.target.checked)}
                                    disabled={
                                      (initDetail &&
                                        initDetail.permission_consents &&
                                        initDetail.permission_consents[0] &&
                                        initDetail.permission_consents[0].role &&
                                        initDetail.permission_consents[0].role === 'viewer') ||
                                      packageId === 1
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </div>
                              {showLogo && (
                                <div className="col-md-12">
                                  <RadioBox>
                                    <div className="form-check form-radio-outline form-radio-primary my-3 p-0 px-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="WithoutinlineRadio1"
                                        value="default"
                                        onChange={handleChangeIconBanner}
                                        checked={iconBanner === 'default'}
                                        disabled={
                                          initDetail &&
                                          initDetail.permission_consents &&
                                          initDetail.permission_consents[0] &&
                                          initDetail.permission_consents[0].role &&
                                          initDetail.permission_consents[0].role === 'viewer'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label className="form-check-label" htmlFor="formCheck1">
                                        <p className="fs-14 m-0 px-2 text-dark">
                                          {i18n[lang].cookiePlusLogo}
                                        </p>
                                      </label>
                                    </div>
                                    <div className="form-check form-radio-outline form-radio-primary my-3 p-0 px-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="WithoutinlineRadio2"
                                        value="custom"
                                        onChange={handleChangeIconBanner}
                                        checked={iconBanner === 'custom'}
                                        disabled={
                                          initDetail &&
                                          initDetail.permission_consents &&
                                          initDetail.permission_consents[0] &&
                                          initDetail.permission_consents[0].role &&
                                          initDetail.permission_consents[0].role === 'viewer'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label className="form-check-label" htmlFor="formCheck1">
                                        <p className="fs-14 m-0 px-2 text-dark">
                                          {i18n[lang].uploadLogo}
                                        </p>
                                      </label>
                                    </div>
                                  </RadioBox>

                                  {iconBanner === 'custom' ? (
                                    <div className="col-md-12">
                                      <p className="fs-12">{i18n[lang].logoDisplayNote}</p>
                                      {initDetail &&
                                      initDetail.permission_consents &&
                                      initDetail.permission_consents[0] &&
                                      initDetail.permission_consents[0].role &&
                                      initDetail.permission_consents[0].role !== 'viewer' ? (
                                        <Fragment>
                                          <Imagesuploading />
                                          <p
                                            className={`fs-12 my-2 px-2 ${
                                              uploadIconErrors ? 'text-red' : ''
                                            }`}
                                          >
                                            {i18n[lang].logoFileSupport} <br />{' '}
                                            {i18n[lang].logoFileSupportRecommended}
                                          </p>
                                        </Fragment>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingsix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsesix"
                          aria-expanded="false"
                          aria-controls="collapsesix"
                        >
                          {i18n[lang].cookieSettingsIcon}
                        </button>
                      </h2>
                      <div
                        id="collapsesix"
                        className="accordion-collapse collapse"
                        aria-labelledby="collapsesix"
                        data-bs-parent="#accordion-banner"
                      >
                        <div className="accordion-body pt-0">
                          <div className="row my-3">
                            <div className="col-md-12 justify-content-between">
                              <RadioBox>
                                <div className="form-check form-radio-outline form-radio-primary my-3 p-0 box-flex-between px-2">
                                  <div>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="optionUploadIcon1"
                                      id="optionUploadIcon1"
                                      value="true"
                                      onChange={handleOptionUploadIcon}
                                      checked={showIcon}
                                    />
                                    <label className="form-check-label" htmlFor="formCheck1">
                                      <p className="fs-14 m-0 px-2 text-dark">
                                        {i18n[lang].displayIconOnWebsite}
                                      </p>
                                    </label>
                                  </div>
                                  {showIcon && (
                                    <span
                                      onClick={() => showMiniBannerFn()}
                                      className=" -cursor text-right text-blue"
                                    >
                                      <i className="ri-eye-fill align-middle mr-1"></i>{' '}
                                      {i18n[lang].preview}
                                    </span>
                                  )}
                                </div>

                                {showIcon ? (
                                  <Fragment>
                                    <p className={`fs-12  ${packageId === 1 ? 'disable-div' : ''}`}>
                                      {packageId === 1 ? <MdLock /> : ''}{' '}
                                      {i18n[lang].cookieSettingsIconNote}{' '}
                                    </p>

                                    <div className={`${packageId === 1 ? 'disable-div' : ''}`}>
                                      <button
                                        type="button"
                                        className={`btn rounded-pill btn-outline-primary waves-effect waves-light mr-2 px-4 mb-3 ${
                                          optionIconWeb === 'default' ? 'active' : ''
                                        }`}
                                        onClick={() => setOptionIconWeb('default')}
                                      >
                                        {packageId === 1 ? <MdLock /> : ''}{' '}
                                        {i18n[lang].defaultSettings}
                                      </button>
                                      <button
                                        type="button"
                                        className={`btn rounded-pill btn-outline-primary waves-effect waves-light mr-2 px-4 mb-3 ${
                                          optionIconWeb === 'custom' ? 'active' : ''
                                        }`}
                                        onClick={() => setOptionIconWeb('custom')}
                                        disabled={packageId === 1 ? true : false}
                                      >
                                        {packageId === 1 ? <MdLock /> : ''} {i18n[lang].uploadImage}
                                      </button>
                                    </div>

                                    <div className="my-3">
                                      {optionIconWeb === 'custom' ? (
                                        <div className="col-md-12">
                                          {initDetail &&
                                          initDetail.permission_consents &&
                                          initDetail.permission_consents[0] &&
                                          initDetail.permission_consents[0].role &&
                                          initDetail.permission_consents[0].role !== 'viewer' ? (
                                            <Fragment>
                                              <IconUploading />
                                              <p
                                                className={`fs-12 my-2 px-2 ${
                                                  uploadIconErrors ? 'text-red' : ''
                                                }`}
                                              >
                                                {i18n[lang].logoFileSupport} <br />{' '}
                                                {i18n[lang].logoFileSupportRecommended}
                                              </p>
                                            </Fragment>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </Fragment>
                                ) : (
                                  ''
                                )}

                                <div className="form-check form-radio-outline form-radio-primary my-3 p-0 px-2">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="optionUploadIcon2"
                                    id="optionUploadIcon2"
                                    value="false"
                                    onChange={handleOptionUploadIcon}
                                    checked={!showIcon}
                                    disabled={
                                      initDetail &&
                                      initDetail.permission_consents &&
                                      initDetail.permission_consents[0] &&
                                      initDetail.permission_consents[0].role &&
                                      initDetail.permission_consents[0].role === 'viewer'
                                        ? true
                                        : false
                                    }
                                  />
                                  <label className="form-check-label" htmlFor="formCheck1">
                                    <p className="fs-14 m-0 px-2 text-dark">
                                      {i18n[lang].customize}
                                    </p>
                                  </label>
                                </div>
                              </RadioBox>
                              {!showIcon ? (
                                <div>
                                  <p className="fs-12">{i18n[lang].bannerButtonCode}</p>
                                  <textarea
                                    className="form-control"
                                    type="text"
                                    id="textScript"
                                    aria-label="With textarea"
                                    rows="2"
                                    value='<a class="cookieplus-preference-settings">การตั้งค่าคุกกี้</a>'
                                    disabled
                                  ></textarea>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingnine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsenine"
                          aria-expanded="false"
                          aria-controls="collapsenine"
                        >
                          {i18n[lang].customizeCss}
                        </button>
                      </h2>
                      <div
                        id="collapsenine"
                        className="accordion-collapse collapse"
                        aria-labelledby="collapsenine"
                        data-bs-parent="#accordion-banner"
                      >
                        <div
                          className={`accordion-body pt-0 ${packageId === 1 ? 'disable-div' : ''}`}
                        >
                          <div className="row my-3">
                            <div className="col-md-12 d-flex mb-2">
                              <span className="f-14 text-dark">
                                {packageId === 1 ? <MdLock /> : ''} {i18n[lang].addCss}
                              </span>

                              <TooltipBox>
                                <div className="tooltips">
                                  <Tooltip
                                    arrow
                                    placement="right"
                                    TransitionComponent={Zoom}
                                    TransitionProps={{ timeout: 200 }}
                                    title={
                                      <span className={`tooltiptexts`}>
                                        <div className="text-left px-3 box_tooltips">
                                          .ckp-banner-title
                                          <br />
                                          .ckp-banner-content
                                          <br />
                                          .ckp-banner-policy-link
                                          <br />
                                          .ckp-banner-setting-button
                                          <br />
                                          .ckp-banner-accept-button
                                          <br />
                                          .ckp-banner-decline-button
                                          <br />
                                          .ckp-preference-header
                                          <br />
                                          .ckp-preference-category-title
                                          <br />
                                          .ckp-preference-category-content
                                          <br />
                                          .ckp-preference-category-link
                                          <br />
                                          .ckp-preference-accept-button
                                          <br />
                                          .ckp-preference-confirm-button
                                        </div>
                                      </span>
                                    }
                                  >
                                    <i className={`mdi mdi-information label-icon fs-14 mx-1`}></i>
                                  </Tooltip>
                                </div>
                              </TooltipBox>
                            </div>
                            <div className="col-md-12">
                              <textarea
                                className="text-area"
                                rows="5"
                                value={customCSS ? customCSS : ''}
                                onChange={e => setCustomCSS(e.target.value)}
                                disabled={packageId === 1 ? true : false}
                              />
                              <br />
                              <span className="fs-12">{i18n[lang].cssNote}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingten">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseten"
                          aria-expanded="false"
                          aria-controls="collapseten"
                        >
                          {i18n[lang].cookieSettingsManagement}
                        </button>
                      </h2>
                      <div
                        id="collapseten"
                        className="accordion-collapse collapse"
                        aria-labelledby="collapseten"
                        data-bs-parent="#accordion-banner"
                      >
                        <div
                          className={`accordion-body pt-0 ${packageId === 1 ? 'disable-div' : ''}`}
                        >
                          <div className="row my-3">
                            <div className="col-md-10">
                              <p className="f-14 text-dark">
                                {packageId === 1 ? <MdLock /> : ''}
                                {i18n[lang].collapseCookieDetails}
                              </p>
                            </div>

                            <div className="col-md-2  text-right">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={showCollapseCookieDetail}
                                  onChange={() => {
                                    setShowCollapseCookieDetail(!showCollapseCookieDetail)
                                  }}
                                  disabled={
                                    (initDetail &&
                                      initDetail.permission_consents &&
                                      initDetail.permission_consents[0] &&
                                      initDetail.permission_consents[0].role &&
                                      initDetail.permission_consents[0].role === 'viewer') ||
                                    packageId === 1
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionBox>
              </div>
              <div className="col-8" style={{ height: '450px' }}>
                <div className="position-fixed w-52" style={{ top: scrollTop > 90 ? 100 : 170 }}>
                  <div className="row justify-content-center ">
                    <div
                      className={`col-${
                        showDevice === 'desktop' ? '12' : showDevice === 'tablet' ? '10' : '5'
                      } `}
                    >
                      <div className="text-center device-frame">
                        <img
                          src={`/assets/images/wirefram-${showDevice}.jpg`}
                          alt="cookie plus"
                          style={{ width: '100%', height: '450px' }}
                        />
                        {bannerPosition === 'default' && <ExampleBannerDefault />}
                        {bannerPosition === 'bottom' && <ExampleBannerBottom />}
                        {bannerPosition === 'popup' && <ExampleBannerPopup />}
                        {bannerPosition === 'bottomLeft' && <ExampleBannerBottomLeft />}
                        {bannerPosition === 'topLeft' && <ExampleBannerTopLeft />}
                        {bannerPosition === 'bottomRight' && <ExampleBannerBottomRight />}
                        {bannerPosition === 'topRight' && <ExampleBannerTopRight />}
                        {bannerPosition === 'fullTop' && <ExampleBannerFullTop />}
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center mt-3">
                    <div className="col-7 text-center">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="device_desktop"
                          autoComplete="off"
                          onClick={() => setShowDevice('desktop')}
                        />
                        <label
                          className={`btn ${
                            showDevice === 'desktop' ? 'btn-primary' : 'btn-outline-primary'
                          }  w-120px`}
                          htmlFor="device_desktop"
                        >
                          <i className="mdi mdi-desktop-mac label-icon align-middle fs-16 me-2"></i>{' '}
                          {i18n[lang].desktop}
                        </label>

                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="device_tablet"
                          autoComplete="off"
                          onClick={() => setShowDevice('tablet')}
                        />
                        <label
                          className={`btn ${
                            showDevice === 'tablet' ? 'btn-primary' : 'btn-outline-primary'
                          } w-120px`}
                          htmlFor="device_tablet"
                        >
                          <i className="mdi mdi-tablet label-icon align-middle fs-16 me-2"></i>{' '}
                          {i18n[lang].tablet}
                        </label>

                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="device_cellphone"
                          autoComplete="off"
                          onClick={() => setShowDevice('mobile')}
                        />
                        <label
                          className={`btn ${
                            showDevice === 'mobile' ? 'btn-primary' : 'btn-outline-primary'
                          } w-120px`}
                          htmlFor="device_cellphone"
                        >
                          <i className="mdi mdi-cellphone label-icon align-middle fs-16 me-2"></i>{' '}
                          {i18n[lang].mobilePhone}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 text-Gray text-8">
                Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
              </div>
              <div className="col-sm-6 text-Gray text-8">
                <div className="text-sm-end d-none d-sm-block">
                  <a
                    className="text-Gray"
                    href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                    target="_blank"
                  >
                    Privacy Policy.
                  </a>{' '}
                  <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                    Terms and conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Fragment>
  )
}

export default ManageCookieComponent
