import React, { Fragment, useState, useEffect, useRef } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { useHistory, useLocation } from 'react-router-dom'
import { activityLog } from '../../apis/management/api'
import { TableBox, TooltipBox } from './style'
import SecondaryMenuComponent from '../../components/menu/secondary-nav'
import { i18n } from '../../helper/i18n'
import { getCookie } from '../../helper/helper'

const { REACT_APP_PREDICTIVE } = process.env

const ActivityLogComponent = props => {
  const history = useHistory()
  const [listActivity, setListActivity] = useState()
  const [detailLang, setDetailLang] = useState('th')
  const [textSearch, setTextSearch] = useState('')
  const [paginationPage, setPaginationPage] = useState(1)
  const [active, setActive] = useState(false)
  const id = props.match.params.id
  const [lang, setLang] = useState(getCookie('lang'))
  useEffect(() => {
    setLang(props.langFn)
    setListActivity([])
    setPaginationPage(1)
    getActivityLog(1, '')
  }, [props.langFn])

  const pinMenu = () => {
    setActive(!active)
    if (active) {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover-active'
    } else {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover'
    }
  }
  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-45%, -50%)',
      width: '400px',
      height: 'auto',
      borderRadius: '10px',
      padding: '0px',
      border: 'unset',
      background: 'unset'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1003'
    }
  }
  const tableStyles = {
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: '500'
      }
    },
    headCells: {
      style: {
        backgroundColor: '#f3f6f9',
        fontSize: '14px'
      }
    },
    cells: {
      style: {
        // paddingLeft: "8px", // override the cell padding for data cells
        // paddingRight: "8px",
      }
    }
  }

  const columns = [
    {
      name: i18n[lang].date,
      selector: row => row.updated_at,
      width: '250px',
      sortable: true
    },
    {
      name: i18n[lang].users,
      selector: row => row.name,
      sortable: true
    },
    {
      name: i18n[lang].domain,
      selector: row => row.domain
    },
    {
      name: i18n[lang].activity,
      selector: row => row.activity
    }
  ]
  useEffect(() => {
    document.title = 'บันทึกกิจกรรม | CookiePlus'
    if (!listActivity) {
      getActivityLog()
    }
  }, [])

  const convertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'numeric' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
    let res = day + '/' + month + '/' + year + ' ' + time
    return res
  }

  const getActivityLog = async (page = 1, text_search = '') => {
    let obj = {
      consent_id: id,
      text_search: text_search,
      page: page,
      limit: 20
    }
    const { data, status } = await activityLog(obj)
    let res = []
    if (data && data.data && data.data.rows && data.data.rows.length > 0 && status === 200) {
      let _tempData = {}
      for (let i = 0; i < data.data.rows.length; i++) {
        _tempData = {
          domain: data.data.rows[i].consent.domain,
          name: data.data.rows[i].user.email,
          activity:
            props.langFn === 'th'
              ? data.data.rows[i].action[props.langFn]
              : customLang(data.data.rows[i].action[props.langFn]),
          updated_at: convertDate(data.data.rows[i].created_at)
        }
        res.push(_tempData)
      }
      if (page > 1) {
        let mergeData = listActivity
        mergeData.push(...res)

        setListActivity(mergeData.map(item => item))
      } else {
        setListActivity(res)
      }
    }
  }

  const searchLog = async txt => {
    if (txt.length > 2) {
      getActivityLog(1, txt)
      setTextSearch(txt)
    } else {
      getActivityLog(1, '')
      setTextSearch('')
    }
  }

  const handleChangePage = async e => {
    if (e * 10 === listActivity.length) {
      let page = paginationPage + 1
      setPaginationPage(page)
      await getActivityLog(page, textSearch)
    }
  }
  const customLang = text => {
    if (text === 'create domain') {
      return i18n[props.langFn].addDomain
    }
    if (text === 'delete domain') {
      return i18n[props.langFn].deleteDomain
    }
    if (text === 'update config banner') {
      return i18n[props.langFn].saveBannerSettings
    }
    if (text === 'publish consent') {
      return i18n[props.langFn].publishBanner
    }
    if (text === 'create cookie category') {
      return i18n[props.langFn].addCookieCategorys
    }
    if (text === 'create cookie') {
      return i18n[props.langFn].addCookie
    }
    if (text === 'scan cookie') {
      return i18n[props.langFn].scanCookies
    }
    if (text === 'delete cookie category') {
      return i18n[props.langFn].deleteCookieCategory
    }
    if (text === 'update cookie category') {
      return i18n[props.langFn].editCookieCategory
    }
    if (text === 'delete cookie') {
      return i18n[props.langFn].deleteCookie
    }
    if (text === 'update cookie') {
      return i18n[props.langFn].editCookies
    }
    if (text === 'mapping cookie to cookie category') {
      return i18n[props.langFn].addCookieToCategorys
    }
    if (text === 'invite user') {
      return i18n[props.langFn].grantPermissions
    }
    if (text === 'delete permission') {
      return i18n[props.langFn].removeUserPermissions
    }
    if (text === 'edit permission') {
      return i18n[props.langFn].editUserPermissions
    }
    if (text === 'upload logo') {
      return i18n[props.langFn].addLogo
    }
    if (text === 'change package domain') {
      return i18n[props.langFn].changeDomainPackage
    }
    if (text === 'change setting consent') {
      return i18n[props.langFn].modifyContentSettings
    }
    if (text === 'stop scan cookie') {
      return i18n[props.langFn].stopCookieScan
    }
  }
  return (
    <Fragment>
      <SecondaryMenuComponent pinMenuFn={pinMenu} id={props.match.params.id} langFn={lang} />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="card ">
              <div className="card-header fixed_header py-2">
                <div className="row">
                  <div className="col-6">
                    {' '}
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="card-title mb-0 py-2">{i18n[lang].activityLog}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    {' '}
                    <div className=" float-end mx-2">
                      <div className="d-flex ">
                        <form className="app-search d-none d-md-block p-0">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={i18n[lang].search}
                              autoComplete="off"
                              id="search-options"
                              onChange={e => searchLog(e.target.value)}
                            />
                            <span className="mdi mdi-magnify search-widget-icon"></span>
                            <span
                              className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                              id="search-close-options"
                            ></span>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{' '}
              <div className="card-body card-table-fix">
                <div id="customerList">
                  <div className="table-responsive table-card  mb-1">
                    <TableBox>
                      {listActivity ? (
                        <DataTable
                          pagination={true}
                          onChangePage={e => handleChangePage(e)}
                          className="-fadeIn mt-0"
                          center={true}
                          columns={columns}
                          data={listActivity}
                          customStyles={tableStyles}
                        />
                      ) : (
                        <div className="zero-center">
                          <div className="zero-content">
                            <img
                              src="../assets/images/Zero-domain.png"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      )}
                    </TableBox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 text-Gray text-8">
                Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
              </div>
              <div className="col-sm-6 text-Gray text-8">
                <div className="text-sm-end d-none d-sm-block">
                  <a target="_blank">Privacy Policy.</a> Terms and conditions
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Fragment>
  )
}

export default ActivityLogComponent
