import React, { Fragment, useState, useEffect, useRef } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { useHistory, useLocation } from 'react-router-dom'
import { acceptConsentList, consentExportCSV, consentById } from '../../apis/management/api'
import SecondaryMenuComponent from '../../components/menu/secondary-nav'
import Tooltip from '@mui/material/Tooltip'
import { TableBox, TooltipBox, FooterBox, ContentBox } from './style'
import Flatpickr from 'react-flatpickr'
import Modal from 'react-modal'
import { LuSearch, LuX } from 'react-icons/lu'
import { i18n } from '../../helper/i18n'
import { getCookie } from '../../helper/helper'

const { REACT_APP_PREDICTIVE } = process.env

const RecordsAcceptComponent = props => {
  const id = props.match.params.id

  const [listCookie, setListCookie] = useState()
  const [dataNotFound, setDataNotFound] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [isExportLoading, setIsExportLoading] = useState(false)
  const [isShowLoading, setIsShowLoading] = useState(false)
  const [detailLang, setDetailLang] = useState('th')
  const [textSearch, setTextSearch] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [declineStatus, setDeclineStatus] = useState()
  const [paginationPage, setPaginationPage] = useState(1)
  const [initDetail, setInitDetail] = useState()
  const fp = useRef(null)
  const [rangeDate, setRangeDate] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isLastPage, setIsLastPage] = useState(false)
  const [filterCate, setFilterCate] = useState(['', '', false])
  const [filterDevice, setFilterDevice] = useState(['', ''])
  const [lang, setLang] = useState(getCookie('lang'))

  const [columns, setColumns] = useState([
    {
      name: i18n[props.langFn].date,
      selector: row => row.date,
      width: '150px',
      sortable: true,
      field: 'date'
    },
    {
      name: i18n[props.langFn].consentId,
      width: '150px',
      selector: row => row.session_id,
      field: 'session_id'
    },
    {
      name: i18n[props.langFn].devices,
      width: '150px',
      selector: row => row.device,
      field: 'device'
    },
    {
      name: i18n[props.langFn].ipAddress,
      width: '130px',
      selector: row => row.ip_address,
      field: 'ip_address'
    },
    {
      name: i18n[props.langFn].page,
      width: '170px',
      selector: row => row.page,
      field: 'page'
    },
    {
      name: i18n[props.langFn].consent,
      selector: row => row.consent_type,
      width: '200px',
      field: 'consent_type'
    },
    {
      name: i18n[props.langFn].bannerVersion,
      width: '120px',
      selector: row => row.version,
      field: 'version'
    }
  ])
  useEffect(() => {
    setLang(props.langFn)
    if (listCookie) {
      setColumns([
        {
          name: i18n[props.langFn].date,
          selector: row => row.date,
          width: '150px',
          sortable: true,
          field: 'date'
        },
        {
          name: i18n[props.langFn].consentId,
          width: '150px',
          selector: row => row.session_id,
          field: 'session_id'
        },
        {
          name: i18n[props.langFn].devices,
          width: '150px',
          selector: row => row.device,
          field: 'device'
        },
        {
          name: i18n[props.langFn].ipAddress,
          width: '130px',
          selector: row => row.ip_address,
          field: 'ip_address'
        },
        {
          name: i18n[props.langFn].page,
          width: '170px',
          selector: row => row.page,
          field: 'page'
        },
        {
          name: i18n[props.langFn].consent,
          selector: row => row.consent_type,
          width: '200px',
          field: 'consent_type'
        },
        {
          name: i18n[props.langFn].bannerVersion,
          width: '120px',
          selector: row => row.version,
          field: 'version'
        }
      ])
      setIsLastPage(false)
      setPaginationPage(1)
      setTextSearch('')
      getCookieListFn(1, '', 'new')
    }
  }, [props.langFn])
  const customStylesModalsm = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-45%, -50%)',
      width: '400px',
      height: 'auto',
      borderRadius: '10px',
      padding: '0px',
      border: 'unset',
      background: 'unset'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1003'
    }
  }
  const [active, setActive] = useState(false)
  const pinMenu = () => {
    setActive(!active)
    if (active) {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover-active'
    } else {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover'
    }
  }
  const getConsentById = async () => {
    const { data, status } = await consentById(id)
    if (data && status === 200) {
      setInitDetail(data.data.permission_consents[0].role)
    }
  }

  useEffect(() => {
    document.title = 'บันทึกการยอมรับคุกกี้ | CookiePlus'
    getCookieListFn()
    getConsentById()
  }, [])

  useEffect(() => {
    getCookieListFn(1, textSearch)
  }, [isLastPage])

  const tableStyles = {
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: '500'
      }
    },
    headCells: {
      style: {
        backgroundColor: '#f3f6f9',
        fontSize: '14px'
      }
    }
  }

  const convertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'numeric' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
    let res = day + '/' + month + '/' + year + ' ' + time
    return res
  }

  const getCookieListFn = async (page = 1, text_search = '', option = '') => {
    let params = `consent_id=${id}&page=${page}&limit=20`
    let obj = {
      consent_id: id
    }
    if (option !== 'new') {
      if (startDate) {
        params += `&start_date=${startDate}`
      }

      if (endDate) {
        params += `&end_date=${endDate}`
      }

      if (textSearch) {
        params += `&${[filterCate[0]]}=${textSearch}`
      }

      if (filterDevice[0]) {
        params += `&${[filterCate[0]]}=${filterDevice[0]}`
      }
    }

    obj.params = params
    if (isLastPage && option !== 'new') {
      return
    }

    const { data, status } = await acceptConsentList(obj)
    if (status === 200) {
      let res = []
      if (data?.data && data.data.length > 0) {
        setDataNotFound(false)

        if (data.data.length < 20) {
          setIsLastPage(true)
        }

        let _tempData = {}
        if (!data.data[0].ip) {
          setColumns([
            {
              name: i18n[props.langFn].date,
              selector: row => row.date,
              width: '150px',
              field: 'date'
            },
            {
              name: i18n[props.langFn].consentId,
              width: '150px',
              selector: row => row.session_id,
              field: 'session_id'
            },
            {
              name: i18n[props.langFn].devices,
              width: '150px',
              selector: row => row.device,
              field: 'device'
            },
            {
              name: i18n[props.langFn].page,
              width: '170px',
              selector: row => row.page,
              field: 'page'
            },
            {
              name: i18n[props.langFn].consent,
              selector: row => row.consent_type,
              width: '200px',
              field: 'consent_type'
            },
            {
              name: i18n[props.langFn].bannerVersion,
              width: '120px',
              selector: row => row.version,
              field: 'version'
            }
          ])
        }
        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i].status === 'partial accept') {
            if (data.data[i].decline[i]) {
              setDeclineStatus(data.data[i].decline[i][detailLang])
            }
          }

          _tempData = {
            date: convertDate(data.data[i].created_at),
            session_id: data.data[i].session_id,
            ip_address: data.data[i].ip,
            page: data.data[i].page,
            device: (
              <Fragment>
                <span className="text-capitalize">{deviceFormat(data.data[i].device)}</span>
              </Fragment>
            ),
            consent_type: (
              <TooltipBox>
                <p className="float-left-mb0">
                  {data.data[i].status === 'partial accept'
                    ? i18n[props.langFn].acceptSome
                    : data.data[i].status === 'accept all'
                    ? i18n[props.langFn].acceptAll
                    : data.data[i].status === 'close banner'
                    ? i18n[props.langFn].closeBanner
                    : i18n[props.langFn].declineAll}
                </p>
                <div className="tooltips">
                  {data.data[i].status === 'partial accept' && (
                    <Tooltip
                      title={
                        <span className={`tooltiptexts`}>
                          <div className="text-left px-3 box_tooltips">
                            <p className="py-2 my-2 border-bottom">
                              {data.data[i].status === 'partial accept'
                                ? i18n[props.langFn].acceptSome
                                : data.data[i].status === 'accept all'
                                ? i18n[props.langFn].acceptAll
                                : i18n[props.langFn].declineAll}
                            </p>
                            {data.data[i].allowed.length !== 0 &&
                              data.data[i].allowed.map((item, i) => {
                                return (
                                  <p key={i}>
                                    <i
                                      className={`mdi mdi-check text-success label-icon align-middle fs-16 mx-1`}
                                    ></i>{' '}
                                    {item[props.langFn]}
                                  </p>
                                )
                              })}

                            {data.data[i].decline.length !== 0 &&
                              data.data[i].decline.map((item, i) => {
                                if (!item.en.toLowerCase().includes('necessary')) {
                                  return (
                                    <p key={i}>
                                      <i
                                        className={`mdi mdi-window-close text-danger label-icon align-middle fs-16 mx-1`}
                                      ></i>{' '}
                                      {item[props.langFn]}
                                    </p>
                                  )
                                }
                              })}
                          </div>
                        </span>
                      }
                    >
                      <i className={`mdi mdi-information label-icon fs-16 mx-2 link-edit`}></i>
                    </Tooltip>
                  )}
                </div>
              </TooltipBox>
            ),
            version: <p>{data.data[i].version} </p>
          }

          res.push(_tempData)
        }
        if (page > 1) {
          let mergeData = listCookie
          mergeData.push(...res)

          setListCookie(mergeData.map(item => item))
        } else {
          setListCookie(res)
        }
        setIsShowLoading(false)
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      } else {
        if (page > 1) {
          setIsLastPage(true)

          setTimeout(() => {
            setIsShowLoading(false)
            setIsLoading(false)
          }, 1000)
        } else {
          setDataNotFound(true)
          setListCookie('')
          setIsShowLoading(false)
        }
      }
    } else {
      setIsShowLoading(false)
      setIsLoading(false)
    }
  }
  const searchCookie = async txt => {
    setTextSearch(txt)
  }

  const handleSearchCookie = () => {
    setIsShowLoading(true)
    setIsLoading(true)
    setListCookie([])
    setIsLastPage(false)
    setPaginationPage(1)
    getCookieListFn()
  }

  const datePickr = date => {
    setRangeDate(date)
    if (date[1]) {
      let startDate = new Date(date[0])
      let endDate = new Date(date[1])

      let sDate = `${
        startDate.toLocaleString('sv-SE', { timeZone: 'UTC' }).split(' ')[0]
      }T17:00:00.000Z`
      let eDate = `${endDate.toLocaleString('sv-SE').split(' ')[0]}T16:59:59.000Z`
      setStartDate(sDate)
      setEndDate(eDate)
    }
  }

  const handleScroll = event => {
    if (
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop + 10 >
      event.currentTarget.clientHeight
    ) {
      if (!isLoading) {
        let page = paginationPage + 1
        setPaginationPage(page)
        getCookieListFn(page, textSearch)
      }
    }
  }

  const exportPDF = async accept_id => {
    setIsExportLoading(true)
    try {
      const { status } = await consentExportCSV(accept_id, startDate, endDate)
      if (status === 200) {
        setTimeout(() => {
          setErrorMsg('')
          setIsExportLoading(false)
        }, 2000)
      } else if (status === 413) {
        setErrorMsg(i18n[lang].fileTooLarge)
        setOpenErrorModal(true)
        setIsExportLoading(false)
      } else {
        setErrorMsg(i18n[lang].serverConnectionError)
        setOpenErrorModal(true)
        setIsExportLoading(false)
      }
    } catch (error) {
      setIsExportLoading(false)
      setErrorMsg(i18n[lang].serverConnectionError)
      setOpenErrorModal(true)
    }
  }

  const deviceFormat = device => {
    if (device === 'web') {
      return i18n[props.langFn].computer
    } else if (device === 'mobile') {
      return i18n[props.langFn].mobileAndTablet
    } else {
      return device
    }
  }
  return (
    <Fragment>
      <SecondaryMenuComponent id={props.match.params.id} langFn={lang} />
      <div className="main-content">
        <ContentBox>
          <div className="page-content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header py-2">
                  <div className="row m-0">
                    <div className="col-2">
                      <h6 className="card-title mb-0 py-2">{i18n[lang].consentRecord} </h6>
                    </div>
                    <div className="col-10 p-0">
                      <ul className="nav w-100 justify-content-end">
                        <li className="nav-item">
                          {' '}
                          {initDetail === 'viewer' || initDetail === 'editor' ? (
                            <Fragment />
                          ) : (
                            <span className={`float-end text-primary link-edit mx-2`}>
                              <button
                                className="btn btn-primary btn-label float-end mx-2"
                                onClick={async () => exportPDF(id)}
                                style={{ height: 38 }}
                                disabled={isExportLoading ? true : false}
                              >
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    {isExportLoading ? (
                                      <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                      </div>
                                    ) : (
                                      <i className="mdi mdi-tray-arrow-up label-icon align-middle fs-14 me-2"></i>
                                    )}
                                  </div>
                                </div>

                                <div className="flex-grow-1">Export</div>
                              </button>
                            </span>
                          )}
                        </li>
                        <li className="nav-item mx-2">
                          <div className="input-group">
                            <Flatpickr
                              style={{ width: '232px' }}
                              ref={fp}
                              placeholder="YYYY-MM-DD to YYYY-MM-DD"
                              options={{
                                mode: 'range',
                                dateFormat: 'Y-m-d',
                                minDate: '2020-01',
                                maxDate: 'today'
                              }}
                              value={rangeDate}
                              onChange={date => (datePickr(date), setIsLastPage(true))}
                            />
                            <div
                              className="input-group-text btn-primary text-white"
                              onClick={() => {
                                fp.current.flatpickr.open()
                              }}
                            >
                              <i className="ri-calendar-2-line"></i>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item mx-2 text-right">
                          {' '}
                          <form className="app-search d-none d-md-block p-0">
                            <div className="position-relative ">
                              <div
                                className="form-control input-white"
                                style={{ width: 280 }}
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="true"
                                aria-expanded="true"
                              >
                                <LuSearch className="fixed-magnify-icon" />
                                <div className="text-left">
                                  {filterCate[1] ? (
                                    filterCate[2] ? (
                                      <Fragment>
                                        <label className="lightBlue">{filterCate[1]} | </label>{' '}
                                        {filterDevice[1]
                                          ? filterDevice[1]
                                          : i18n[lang].chooseForSearch}
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        <label className="lightBlue">{filterCate[1]} | </label>
                                        <input
                                          className="txt-holder-grey"
                                          onChange={e => (
                                            setTextSearch(e.target.value), setIsLastPage(true)
                                          )}
                                          value={textSearch}
                                          placeholder={i18n[lang].typingSearch}
                                          style={{
                                            width: 85,
                                            border: 'none',
                                            marginLeft: 1,
                                            outline: 'none'
                                          }}
                                        />
                                      </Fragment>
                                    )
                                  ) : (
                                    <label style={{ color: '#ced4da' }}>{i18n[lang].search}</label>
                                  )}
                                </div>
                              </div>
                              <LuX
                                className="fixed-x-icon -cursor"
                                style={{ zIndex: 99999 }}
                                onClick={() => (
                                  setFilterCate(['', '', false]),
                                  setTextSearch(''),
                                  setFilterDevice(['', '']),
                                  getCookieListFn(1, '', 'new')
                                )}
                              />
                              <ul
                                className="dropdown-menu"
                                style={{ width: '-webkit-fill-available' }}
                                aria-labelledby="defaultDropdown"
                              >
                                {filterCate[0] === 'device' && filterCate[2] ? (
                                  <Fragment>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => (
                                          setFilterDevice(['web', i18n[lang].computer]),
                                          setTextSearch(''),
                                          setIsLastPage(true)
                                        )}
                                      >
                                        {i18n[lang].computer}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => (
                                          setFilterDevice(['mobile', i18n[lang].mobileAndTablet]),
                                          setTextSearch(''),
                                          setIsLastPage(true)
                                        )}
                                      >
                                        {i18n[lang].mobileAndTablet}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => (
                                          setFilterDevice(['others', i18n[lang].others]),
                                          setTextSearch(''),
                                          setIsLastPage(true)
                                        )}
                                      >
                                        {i18n[lang].others}
                                      </a>
                                    </li>
                                  </Fragment>
                                ) : filterCate[0] === 'status' && filterCate[2] ? (
                                  <Fragment>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => (
                                          setFilterDevice(['accept all', i18n[lang].acceptAll]),
                                          setTextSearch(''),
                                          setIsLastPage(true)
                                        )}
                                      >
                                        {i18n[lang].acceptAll}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => (
                                          setFilterDevice([
                                            'partial accept',
                                            i18n[lang].acceptSome
                                          ]),
                                          setTextSearch(''),
                                          setIsLastPage(true)
                                        )}
                                      >
                                        {i18n[lang].acceptSome}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => (
                                          setFilterDevice(['declineAll', i18n[lang].declineAll]),
                                          setTextSearch(''),
                                          setIsLastPage(true)
                                        )}
                                      >
                                        {i18n[lang].declineAll}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => (
                                          setFilterDevice(['close banner', i18n[lang].closeBanner]),
                                          setTextSearch(''),
                                          setIsLastPage(true)
                                        )}
                                      >
                                        {i18n[lang].closeBanner}
                                      </a>
                                    </li>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => (
                                          setFilterCate([
                                            'session_id',
                                            i18n[lang].consentId,
                                            false
                                          ]),
                                          setTextSearch(''),
                                          setIsLastPage(true)
                                        )}
                                      >
                                        {i18n[lang].consentId}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => (
                                          setFilterCate(['device', i18n[lang].devices, true]),
                                          setTextSearch(''),
                                          setIsLastPage(true)
                                        )}
                                      >
                                        {i18n[lang].devices}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => (
                                          setFilterCate(['ip', i18n[lang].ipAddress, false]),
                                          setTextSearch(''),
                                          setIsLastPage(true)
                                        )}
                                      >
                                        {i18n[lang].ipAddress}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => (
                                          setFilterCate(['page_consent', i18n[lang].page, false]),
                                          setTextSearch(''),
                                          setIsLastPage(true)
                                        )}
                                      >
                                        {i18n[lang].page}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => (
                                          setFilterCate(['status', i18n[lang].consent, true]),
                                          setTextSearch(''),
                                          setIsLastPage(true)
                                        )}
                                      >
                                        {i18n[lang].consent}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => (
                                          setFilterCate([
                                            'version',
                                            i18n[lang].bannerVersion,
                                            false
                                          ]),
                                          setTextSearch(''),
                                          setIsLastPage(true)
                                        )}
                                      >
                                        {i18n[lang].bannerVersion}
                                      </a>
                                    </li>
                                  </Fragment>
                                )}
                              </ul>
                            </div>
                          </form>
                        </li>
                        <li className="nav-item mx-2">
                          <button
                            className="btn btn-primary btn-load"
                            style={{ width: 80 }}
                            onClick={() => handleSearchCookie()}
                            disabled={isShowLoading}
                          >
                            <span className="d-flex align-items-center justify-content-center">
                              {isShowLoading ? (
                                <Fragment>
                                  {' '}
                                  <span
                                    className={`spinner-border flex-shrink-0`}
                                    role="status"
                                  ></span>{' '}
                                  <span className=" ms-2 mr-2">{i18n[lang].search}</span>{' '}
                                </Fragment>
                              ) : (
                                i18n[lang].search
                              )}
                            </span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-body card-table-fix p-0" style={{ overflowY: 'unset' }}>
                  <div id="customerList">
                    <div className="table-responsive content-scroll" onScroll={handleScroll}>
                      {listCookie ? (
                        <div className="border-shadow rounded bg-white box-shadow-center table-border-radius">
                          <table className="table m-0" style={{ tableLayout: 'fixed' }}>
                            <thead className="table-light">
                              <tr>
                                {columns.map((col, iCol) => (
                                  <td key={iCol} style={{ width: col.width }}>
                                    {col.name}
                                  </td>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {listCookie.map((row, iRow) => (
                                <tr key={iRow}>
                                  {columns.map((col, iCol) => (
                                    <td key={iCol} style={{ width: col.width }}>
                                      {row[col.field]}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                              {isLoading && (
                                <tr>
                                  <td
                                    className={`text-center ${isShowLoading ? 'pt-15' : ''}`}
                                    style={{ border: 'none' }}
                                    colSpan={columns.length}
                                  >
                                    <span
                                      className="spinner-border flex-shrink-0"
                                      style={{
                                        border: '0.25em solid #1298be',
                                        borderRightColor: 'transparent'
                                      }}
                                      role="status"
                                    ></span>
                                    {/* <img
                                      src="../assets/images/Loading-spin.gif"
                                      className="loading-sm"
                                    /> */}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="zero-center">
                          <div className="zero-content">
                            {dataNotFound ? (
                              <div>
                                <img
                                  src="../assets/images/searchnotfound.png"
                                  className="w-100 mb-2 mt-4"
                                />
                                <p>{i18n[lang].noSearchResults}</p>
                              </div>
                            ) : (
                              <img
                                src="../assets/images/Zero-domain.png"
                                alt=""
                                className="img-fluid"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContentBox>

        <Modal
          shouldCloseOnOverlayClick={true}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openErrorModal}
          contentLabel="modal"
          style={customStylesModalsm}
          onRequestClose={() => setOpenErrorModal(false)}
        >
          <div className="modal-dialog">
            <div className="modal-content p-1">
              <div className="modal-body text-center">
                <img src="/assets/images/warning.png" alt="predictive" />
                <div className="mt-3">
                  <label htmlFor="basiInput" className="form-label">
                    <strong className="fs-20">{i18n[lang].sorry}</strong>
                  </label>
                  <br />
                  <span className="fs-14">
                    {errorMsg}
                    {/* ไฟล์นี้มีขนาดใหญ่เกินขนาดดาวน์โหลดสูงสุด <br />
                    โปรดติดต่อ support เพื่อขอข้อมูล */}
                  </span>
                </div>
              </div>
              <div className="modal-footer justify-content-center ">
                <button
                  type="button"
                  className={`btn w-lg btn-warning waves-effect waves-light  `}
                  onClick={() => setOpenErrorModal(false)}
                >
                  {i18n[lang].ok}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <FooterBox>
          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6 text-Gray text-8">
                  Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
                </div>
                <div className="col-sm-6 text-Gray text-8">
                  <div className="text-sm-end d-none d-sm-block">
                    <a
                      className="text-Gray"
                      href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                      target="_blank"
                    >
                      Privacy Policy.
                    </a>{' '}
                    <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                      Terms and conditions
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </FooterBox>
      </div>
    </Fragment>
  )
}

export default RecordsAcceptComponent
